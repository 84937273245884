.container {
  width: 20rem;
  h4 {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}

.fleet-vehicle-axes,
.fleet-vehicle-tires,
.fleet-width,
.fleet-height,
.trips-distance,
.trips-time-spent,
.trips-money-spent,
.toll-document-amount {
  width: 10rem;
}

.toll-documents-status {
  width: 16rem;
}

.transactions-base-state,
.transactions-license-plate-number,
.transactions-license-plate-state,
.transactions-transaction-date,
.transactions-upload-date {
  width: 18rem;
}

.fleet-license-plate-state,
.fleet-license-plate-number,
.trips-license-plate-state,
.trips-license-plate-number,
.trips-start-point,
.trips-end-point,
.trips-start-date,
.trips-gps-calculated-tolls,
.trips-end-date,
.roles-name,
.toll-documents-toll-document-date,
.toll-document-tag-transponder {
  width: 16rem;
}

.toll-documents-toll-document-number {
  width: 17rem;
}

.fleet-vin {
  min-width: 18rem;
  overflow-wrap: break-word;
}

.toll-documents-invoice-issuer,
.toll-documents-toll-document-name {
  min-width: 20rem;
  overflow-wrap: break-word;
}

.transactions-plaza,
.transactions-facility,
.customers-status,
.users-status,
.toll-document-transaction-date-time,
.users-roles {
  width: 20rem;
}

.toll-document-description-location {
  width: 22rem;
}

.fleet-tag-transponder,
.transactions-tag-transponder,
.transactions-toll-agency,
.customers-company-name,
.transactions-status,
.users-name {
  width: 24rem;
}

.customers-admin-e-mail-address,
.customers-address,
.users-email-address {
  max-width: 20rem;
  overflow-wrap: break-word;
}

.roles-users {
  width: 40rem;
}

.roles-description {
  width: 50rem;
}
