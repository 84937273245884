
          @use "@assets/scss/_config.scss" as *;
        
.container {
  padding: 0rem 3rem 0rem 3rem;
  overflow: auto;

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .common-no-data {
    height: 53.2rem;
  }

  .no-data-height {
    height: 35rem;
  }

  .accordion {
    margin-top: 0.63rem;
    .subject {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 2rem;
      gap: 2rem;
      line-height: 2rem;
      .col-1 {
        align-items: center;
      }
      .col-2 {
        align-items: center;
      }
      .text-header {
        font-size: small;
      }
      .amount {
        margin-top: 0.2rem;
        width: 30%;
      }
      .description {
        width: 100%;
      }
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .col-1 {
      display: flex;
      gap: 0.37rem;
      align-items: center;
      .vehicle-name {
        color: $primary-600;
        font-weight: 600;
      }
    }
    .col-2 {
      display: flex;
      gap: 1.88rem;
      align-items: center;
      .time-stamp {
        color: $neutral-500;
      }
      .map {
        border: 0 !important;
        .icon {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-height {
  height: 40rem;
}

.common-loading-height {
  height: 53.2rem;
}

.pagination {
  width: 80%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 10%;
  display: flex;
  justify-content: space-between;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  margin: 3rem 3rem 1rem 3rem;

  .select-items {
    margin-left: 2rem;
  }

  .date-filters {
    margin-top: -0.6rem;
    margin-left: 2rem;
    width: 25%;
  }
}

.container-height {
  height: 35rem;
}

.common-container-height {
  height: 53.2rem;
}
