
          @use "@assets/scss/_config.scss" as *;
        
@use '../../assets/column-sizes' as *;

.container {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  height: 100%;
  cursor: pointer;

  .data-red {
    color: red;
  }
  .data-grey {
    color: #B3B3B3;
  }

  .data-green {
    color: #02b502; // green;
  }
  .data {
    white-space: wrap;
    overflow: hidden;
    text-overflow: break;
  }

  .search-highlight {
    display: inline-block;
    background: $secondary-g-700;
    color: $neutral-g-0;
    border-radius: 2px;
  }

  &.not-found {
    color: black;
  }
}
