
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem 2rem;
  padding: 2rem 3rem;
  min-height: 53.2rem;
  align-content: flex-start;
  overflow: auto;

  .input {
    width: 35rem;
    flex-grow: 1;
  }
}

.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.geotab-toggle,
.status-toggle {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .label {
    padding: 0 1.2rem;
    text-align: left;
    font-weight: 600;
    @extend %small-text;
  }

  .toggle {
    margin-top: 0.6rem;
  }
}
