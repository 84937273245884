
          @use "@assets/scss/_config.scss" as *;
        
.container {
  padding: 2rem 3rem;
  height: 53.2rem;
  overflow: auto;

  .list {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin-top: 2rem;

    .ind {
      height: 4%;
    }

    & > li {
      width: 100%;
    }
  }
}

.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.accordion {
  width: 100%;

  .content {
    padding: 1.6rem 1.6rem 1.6rem 5.6rem;
    overflow-y: auto;
    display: flex;
    align-items: center;

    div {
      width: 18rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-right: 8rem;
    }

    h3 {
      font-weight: 700;
      font-size: 1.1rem;
      line-height: 1.3rem;
      text-transform: uppercase;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
}

.model-overlayer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(33 33 52 / 40%);
  z-index: 500;
}

.model.profile-edit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 119rem;
  background: #fff;
  border-radius: 8px;
  margin: 0 1rem;
}

.model-head {
  font-size: 1.6rem;
  font-weight: 600;
  background: #f6f6f9;
  border: 1px solid #eaeaef;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 6.4rem;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.model-content {
  padding: 3rem;
  min-height: calc(70vh - 124px);
}
