.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;

  &.open {
    padding: 1rem 1.2rem;

    & > a {
      width: 100%;
    }
  }
}
