
          @use "@assets/scss/_config.scss" as *;
        
.body {
  h1 {
    width: 36rem;
  }

  &.email-sent {
    margin-top: 5.7rem;
  }
}

.container {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot-password {
  display: flex;
  flex-direction: column;
}

.forgot-password p {
  margin-bottom: 0.7rem;
  font-weight: 500;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin: 0;
}
