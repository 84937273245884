
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  .input {
    width: 40rem;
    text-align: left;
  }

  .error {
    color: $danger-600;
    font-size: 1.3rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin: -0.5rem 0;
  }

  button {
    width: 40rem;
    @extend %small-button-text;
  }

  & > a:last-child {
    margin-top: 1rem;
  }
}
