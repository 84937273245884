
          @use "@assets/scss/_config.scss" as *;
        
.container {
  padding: 1.6rem 4rem;
  border-top: 1px solid $neutral-g-300;
  width: 100%;
  margin-top: auto;
  position: sticky;
  bottom: 0;
  display: flex;
  background: $neutral-g-100;

  button {
    height: 3.2rem;
    min-width: 4.9rem;
    width: fit-content;
    border-radius: 4px;
    font-size: 1.2rem;
    line-height: 1.4rem;

    &.action {
      background: #0078d3;
      height: 3.2rem !important;
      min-width: 8.9rem !important;
      color: #fff;
      margin-left: auto;
      transition: 0.2s linear;
      padding: 0.5rem;

      &:hover {
        opacity: 0.8;
        transition: 0.2s linear;
      }

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &.back {
      background: #fff;
      color: #202328;
      border: 1px solid $neutral-g-300;
      transition: 0.2s linear;

      &:hover {
        background: $neutral-g-300;
        transition: 0.2s linear;
      }
    }
  }
}
