@use '../variables/colors' as *;

%text-primary {
  color: $primary-600;

  &:hover {
    color: $primary-500;
  }

  &:focus {
    color: $primary-700;
  }
}

// geotab secondary
%text-g-secondary {
  color: $secondary-g-700;
}

%text-success {
  color: $success-600;

  &:hover {
    color: $success-500;
  }

  &:focus {
    color: $success-700;
  }
}

%text-danger {
  color: $danger-600;

  &:hover {
    color: $danger-500;
  }

  &:focus {
    color: $danger-700;
  }
}

%text-button-disabled {
  color: $disabled;
  cursor: not-allowed;

  &:hover {
    color: $disabled;
  }
}
