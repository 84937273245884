
          @use "@assets/scss/_config.scss" as *;
        
.container {
  margin-top: auto;
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $screen-s) {
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 2rem;
  }
}
