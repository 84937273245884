
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: inline-block;
    margin: 0.5rem;
    animation: loading 1.5s ease-in-out infinite;
  }

  @keyframes loading {
    0%,
    100% {
      transform: scale(0.2);
      background-color: #004b8f;
    }
    42% {
      background-color: #004b8f;
    }
    50% {
      transform: scale(1);
      background-color: #004b8f;
    }
  }

  div:nth-child(0) {
    animation-delay: 0s;
  }
  div:nth-child(1) {
    animation-delay: 0.2s;
  }
  div:nth-child(2) {
    animation-delay: 0.4s;
  }
  div:nth-child(3) {
    animation-delay: 0.6s;
  }
  div:nth-child(4) {
    animation-delay: 0.8s;
  }
  div:nth-child(5) {
    animation-delay: 1s;
  }
}
