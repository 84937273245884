
          @use "@assets/scss/_config.scss" as *;
        
/* stylelint-disable selector-class-pattern */
.container {
  text-transform: capitalize;
  padding: 0.9rem 1.2rem;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;
  background: $tertiary-100;
  border: 1px solid $tertiary-200;
  color: $tertiary-600;

  &[data-status-uppercase='true'] {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.3rem;
  }

  &.container-accepted,
  &.container-matched,
  &.container-success,
  &.container-paid,
  &.container-received,
  &.container-confirmed,
  &.container-active {
    background: $success-100;
    border: 1px solid $success-200;
    color: $success-600;
  }

  &.container-open {
    background: #fff4e6;
    border: 1px solid $warning-200;
    color: #d37e0a;
  }

  &.container-received,
  &.container-duplicate,
  &.container-parsed {
    background: $primary-100;
    border: 1px solid $primary-200;
    color: $primary-600;
  }

  &.container-new-file-uploaded,
  &.container-processing,
  &.container-in-progress,
  &.container-awaiting,
  &.container-pending,
  &.container-invite-sent,
  &.container-sent-invite {
    background: $secondary-100;
    border: 1px solid $secondary-200;
    color: $secondary-600;
  }

  &.container-parse-error,
  &.container-warning,
  &.container-archived,
  &.container-in-archive {
    background: $warning-100;
    border: 1px solid $warning-200;
    color: $warning-600;
  }

  &.container-rejected,
  &.container-match-error,
  &.container-error,
  &.container-inactive,
  &.container-force_change_password {
    background: $danger-100;
    border: 1px solid $danger-200;
    color: $danger-600;
  }
}
