
          @use "@assets/scss/_config.scss" as *;
        
/* stylelint-disable property-no-unknown */
/* stylelint-disable selector-pseudo-class-no-unknown */
@use 'sass:color';

.overlay {
  position: fixed;
  inset: 0;
  bottom: 6rem;
  background: color.adjust($neutral-200, $alpha: -0.3);

  z-index: 200;
  display: none;
  justify-content: flex-start;
  align-items: flex-end;
}

.container {
  display: none;
}

:export {
  backgroundHidden: color.adjust($neutral-200, $alpha: -1);
  backgroundVisible: color.adjust($neutral-200, $alpha: -0.3);
}

@media all and (max-width: 640px) {
  .overlay {
    display: flex;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    background: $neutral-200;

    .link {
      display: flex;
      width: 100%;
      height: 6rem;

      .mobile-nav-item {
        justify-content: flex-end;
        padding: 0 2rem;
        background: inherit;
        transition: 0.3s linear;

        &.active {
          color: $secondary-g-700;
          background: $neutral-100;
        }

        &:hover:not(.active) {
          color: $secondary-g-700;
          background: $neutral-100;
          transition: 0.3s linear;
        }
      }
    }
  }
}
