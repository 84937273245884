
          @use "@assets/scss/_config.scss" as *;
        
.container {
  flex-shrink: 0;
  cursor: pointer;
  @extend %inline-flex-center;

  .box {
    width: 2rem;
    height: 2rem;
    border-radius: 5px;
    font-size: 1.6rem;
    @extend %inline-flex-center;
  }

  &.container-checked,
  &.container-intermediate {
    .box {
      background: #3c5063;
      border: 0.5px solid $neutral-0;
      color: $neutral-0;
    }
  }

  &.container-unchecked {
    .box {
      border: 1px solid $neutral-300;
      background: $neutral-0;
    }

    &:hover {
      .box {
        background: $neutral-100;
      }
    }
  }

  &.container-labelled {
    padding: 0.6rem;
    gap: 0.8rem;

    &.container-unchecked {
      &:hover {
        background: $neutral-100;
        border-radius: 6px;

        .box {
          background: $neutral-0;
        }
      }
    }
  }

  &[data-disabled='true'] {
    cursor: not-allowed;
    color: $disabled;

    .box {
      background: $neutral-200;
      border: 1px solid $neutral-300;
      color: $disabled;
    }

    &.container-unchecked {
      &:hover {
        background: $neutral-0;
        border-radius: 0;

        .box {
          background: $neutral-200;
          border: 1px solid $neutral-300;
          color: $disabled;
        }
      }
    }
  }

  .label-strength {
    font-weight: 600;
  }
}
