
          @use "@assets/scss/_config.scss" as *;
        
/* stylelint-disable property-no-unknown */
/* stylelint-disable selector-pseudo-class-no-unknown */
@use 'sass:color';

.overlay {
  position: fixed;
  inset: 0;
  top: 8.6rem;
  background: color.adjust($neutral-900, $alpha: -0.6);
  z-index: calc($modal-z-index - 10);
  display: none;
  justify-content: flex-end;
}

.container {
  display: none;
}

:export {
  backgroundHidden: color.adjust($neutral-900, $alpha: -1);
  backgroundVisible: color.adjust($neutral-900, $alpha: -0.6);
}

@media all and (max-width: 1300px) {
  .overlay {
    display: flex;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 30rem;
    height: fit-content;
    background: $tertiary-g-600;

    .link {
      display: flex;
      width: 100%;
      height: 6rem;
    }
  }
}

@media all and (max-width: 640px) {
  .overlay,
  .container {
    display: none;
  }
}
