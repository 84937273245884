
          @use "@assets/scss/_config.scss" as *;
        
.container {
  padding: 1.1rem 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: auto;
  border-top: 1px solid $neutral-200;
  background: $neutral-0;
  z-index: 0;
  position: absolute;
  bottom: 0;
}
