@use '../../helpers/variables' as *;

.nav-item {
  font-size: 2.2rem;
  height: 4rem;
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  color: $neutral-600;
  width: 4rem;
  justify-content: flex-start;
  padding: 1rem 0;
  transition: color, background-color 0.3s ease-out;

  &.active {
    background: $primary-100;
    color: $primary-600;

    &:hover {
      color: $primary-600;
      background: $primary-100;
    }
  }

  &:hover {
    transition: color, background-color 0.3s ease-in;
    color: $neutral-800;
    background: $neutral-100;
  }

  .text {
    position: relative;
    display: inline-flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    width: auto;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-transform: capitalize;
  }

  &.open {
    width: 100%;
    margin: 0;
    justify-content: flex-start;
  }
}
