
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  .input {
    width: 40rem;
  }

  button {
    width: 40rem;
    @extend %small-button-text;
  }
}
