
          @use "@assets/scss/_config.scss" as *;
        
.dropdown {
  width: 24rem;

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;

    li {
      width: 100%;
      height: 100%;

      & > * {
        color: $neutral-800;
        width: 100%;
      }
    }
  }

  .dropdown-item {
    padding: 0.8rem;
    gap: 0.8rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
