
          @use "@assets/scss/_config.scss" as *;
        
.container {
  position: relative;
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  background: $neutral-0;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;

  .wrapper {
    height: 5.5rem;
    background: $neutral-0;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    padding: 0 2rem 0 2.4rem;

    @extend %divider;

    .entries {
      @extend %small-button-text;
    }
  }
}
