@use '../variables/colors' as *;

%header1 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.6rem;
}

%header2 {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.2rem;
}

%header3 {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.9rem;
}

%subtitle {
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: $neutral-600;
}

%body {
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
}

%body-highlight {
  @extend %body;

  font-weight: 600;
}

%button-text {
  @extend %body;

  font-weight: 600;
}

%small-text {
  @extend %body;

  font-size: 1.2rem;
  line-height: 1.5rem;
}

%small-button-text {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 600;
}

%table-label {
  @extend %body;

  font-size: 1.1rem;
  line-height: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
}
