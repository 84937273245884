
          @use "@assets/scss/_config.scss" as *;
        
.container {
  background-color: $neutral-g-0;
  max-width: 100vw;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  position: relative;

  & > div {
    grid-area: 1/1/3/2;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .main {
    padding: 0 4rem 0 3.4rem;
    grid-area: 1/2/3/3;
    width: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  &.is-geotab {
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;

    & > div {
      grid-area: 1/1/2/2;
      position: fixed;
      z-index: $navigation-bar-z-index;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .main {
      padding: 0 5rem;
      grid-area: 2/1/4/2;
      margin-top: 8.6rem;

      * {
        border-radius: 0 !important;
      }
    }
  }
}

.is-geotab {
  .main {
    *::-webkit-scrollbar-thumb,
    *::-webkit-scrollbar-track {
      border-radius: 0 !important;
    }
  }
}

@media all and (max-width: 640px) {
  .container {
    &.is-geotab {
      & > div {
        grid-area: 3/1/4/2;
        top: unset;
        bottom: 0;
      }

      .main {
        grid-area: 1/1/3/2;
        margin-top: 0;
        padding: 4rem 1rem 8rem;
        overflow: auto;
      }
    }
  }
}
