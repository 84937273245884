
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 23rem;

  label,
  .display,
  .error-description {
    padding-left: 1.2rem;
  }

  label {
    font-weight: 600;
    @extend %small-text;

    text-transform: capitalize;
  }

  .input-container {
    margin-top: 0.6rem;
    height: 4rem;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    --icon-box-width: 4rem;

    &:hover:not([data-slider-input-disabled='true']):not(:focus-within) {
      .wrapper,
      .icon-container {
        transition: all 0.3s ease;
        border-color: $primary-200;
      }
    }

    &[data-slider-input-disabled='true'] {
      &:focus-within {
        .icon-container {
          border-color: $neutral-200;
        }
      }

      .icon-container {
        background: $neutral-100;
        cursor: not-allowed;
        border-color: 1px solid $neutral-200;
      }

      .wrapper {
        .slider {
          background: $neutral-200;
        }

        .display {
          background: $neutral-100;
          color: $neutral-500;
        }
      }
    }

    &:focus-within {
      .icon-container {
        border-color: $primary-600;
      }
    }

    .icon-container {
      width: var(--icon-box-width);
      height: 100%;
      background: $neutral-100;
      border: 1px solid $neutral-200;
      border-right: none;
      border-radius: 8px 0 0 8px;
      color: $primary-600;
      font-size: 1.6rem;
      transition: all 0.3s ease;
      @extend %inline-flex-center;

      & ~ .wrapper {
        border-radius: 0 8px 8px 0;
        width: calc(100% - var(--icon-box-width));

        .slider {
          width: calc(100% - var(--icon-box-width) - 2px);
        }

        .display {
          padding: 0 1rem;
        }
      }
    }

    .wrapper {
      transition: all 0.3s ease;
      border-radius: 8px;
      border: 1px solid $neutral-200;
      width: 100%;
      height: 100%;
      font-size: 1.4rem;
      line-height: 1.7rem;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      --slider-height: 2px;

      &.focused {
        border: 1px solid $primary-600;
      }

      .slider-rail {
        position: relative;
        width: 100%;
        bottom: 0;
        background: $primary-200;
        height: var(--slider-height);
      }

      .slider {
        height: var(--slider-height);
        width: calc(100% - 2px);
      }

      .display {
        background: $neutral-0;
        height: calc(100% - var(--slider-height));
        padding: 0 1.2rem;
        display: flex;
        align-items: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }

  &.container-error {
    .input-container {
      .wrapper,
      .icon-container {
        border-color: $danger-600;

        &:focus {
          border-color: $danger-600;
        }
      }

      &:hover {
        .wrapper,
        .icon-container {
          border-color: $danger-600;
        }
      }

      .icon-container {
        color: $danger-600;
      }
    }

    .error-description {
      margin-top: 0.4rem;
      color: $danger-600;
      @extend %small-text;
    }
  }
}
