
          @use "@assets/scss/_config.scss" as *;
        
.container {
  padding: 2rem 3rem;
  height: 53.2rem;
  overflow: auto;

  &.with-scrollbar {
    margin-right: 1rem;
  }

  & > h3 {
    margin-top: 2rem;
    @extend %header3;
  }
}

.container::-webkit-scrollbar {
  width: 12px;
}

.container::-webkit-scrollbar-track {
  margin-block: 2rem;
  background: $neutral-150;
  border-radius: 6px;
  position: relative;
  background-clip: padding-box;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 11px;
  background: $neutral-0;
  border: solid $neutral-150;
  border-width: 8px 2px;
  background-clip: padding-box;
}

.loader {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
