
          @use "@assets/scss/_config.scss" as *;
        
.container {
  .closed-custom-icon {
    width: 2.4rem;
    height: 2.4rem;
    padding: 0.9rem;
    border-radius: 50%;
    @extend %inline-flex-center;
  }
  .opened-custom-icon {
    border-radius: 50%;
    font-size: 1.9rem;
    padding: 0.2rem;
    @extend %inline-flex-center;
  }
  .right-icon {
    width: 2.4rem;
    height: 2.4rem;
    padding: 0.9rem;
    background: $neutral-200;
    border-radius: 50%;
    font-size: 1.6rem;
    @extend %inline-flex-center;
  }
  .up-icon {
    width: 2.4rem;
    height: 2.4rem;
    background: $primary-200;
    border-radius: 50%;
    font-size: 1.7rem;
    @extend %inline-flex-center;
  }
  .summary-wrapper {
    column-gap: 2rem;
    transition: all 0.3s ease;
    .title {
      width: 100%;
    }
    .summary {
      display: flex;
      width: 100%;
      align-items: center;
      column-gap: 4rem;
      justify-content: flex-end;
    }
  }
  .themed-header {
    background: $primary-100;
    color: $primary-600;
    font-weight: 600;
    min-height: 2rem !important;
  }
  .curved-header {
    border-radius: 5px;
  }
}
.hover:hover {
  background: $primary-100;
  color: $primary-600;
  font-weight: 600;
}
