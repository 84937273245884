
          @use "@assets/scss/_config.scss" as *;
        
.container {
  gap: 0.95rem;
  font-weight: 600;
  transition: all 0.2s ease-out;
  @extend %inline-flex-center;

  a {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .icon {
    font-size: 1.7rem;
    line-height: 1;
    @extend %inline-flex-center;
  }

  &[data-button-appearance='button'] {
    @each $button, $placeholder in $buttons {
      &.#{$button} {
        @extend %#{$placeholder} !optional;
      }
    }

    &:disabled {
      @extend %button-disabled !optional;
    }
  }

  &[data-button-appearance='text'] {
    padding: 0;
    background: none;
    border-radius: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    @each $text-button, $placeholder in $text-buttons {
      &.#{$text-button} {
        @extend %#{$placeholder} !optional;
      }
    }

    &:disabled {
      @extend %text-button-disabled !optional;
    }
  }

  &[data-button-appearance='icon'] {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: 4.6rem;
    height: 4.6rem;

    .icon {
      font-size: 2rem;
      @extend %inline-flex-center;
    }
    @each $icon-button, $placeholder in $icon-buttons {
      &.#{$icon-button} {
        @extend %#{$placeholder} !optional;
      }
    }

    &:disabled {
      @extend %icon-button-disabled !optional;
    }
  }

  &[data-button-size='l'] {
    font-size: 1.4rem;
    line-height: 1.7rem;
    border-radius: 10px;

    &[data-button-appearance='button'] {
      min-width: 8.2rem;
      height: 4.6rem;
      padding: 0 1.2rem;
    }

    &[data-button-appearance='icon'] {
      width: 4.6rem;
      height: 4.6rem;
    }
  }

  &[data-button-size='s'],
  &[data-button-size='xs'] {
    font-size: 1.2rem;
    line-height: 1.5rem;
    border-radius: 8px;
  }

  &[data-button-size='s'] {
    &[data-button-appearance='button'] {
      min-width: 6.3rem;
      height: 4rem;
      padding: 0 1.2rem;
    }

    &[data-button-appearance='icon'] {
      width: 4rem;
      height: 4rem;
    }
  }

  &[data-button-size='xs'] {
    &[data-button-appearance='button'] {
      height: 3.2rem;
      padding: 0 1.2rem;
    }

    &[data-button-appearance='icon'] {
      width: 3.2rem;
      height: 3.2rem;

      .icon {
        font-size: 1.6rem;
      }
    }
  }
}
