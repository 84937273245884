
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding: 2rem 4rem 1rem;

  button {
    height: 3.2rem;
    min-width: 6rem;
    padding: 1rem;
    width: fit-content;
    border-radius: 4px;
    font-size: 1.2rem;
    line-height: 1.4rem;
    white-space: nowrap;

    &.export {
      background: #fff;
      color: $neutral-g-900;
      border: 1px solid $neutral-g-300;
    }

    &.import {
      background: #0078d3;
      color: #fff;
    }

    &:disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }
}
.modal {
  .modal-content {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    .table-header th {
      vertical-align: bottom;
      border-bottom: 1px solid #ddd;
      padding: 8px;
      line-height: 1.4;
    }
    .table-body {
      text-align: center;
      td {
        padding: 8px;
        line-height: 1.4;
        vertical-align: top;
        border: 1px solid #ddd;
      }
      .vehicle-name {
        display: flex;
        height: 13rem;
        align-items: center;
        justify-content: center;
      }
      .chk-box-action {
        display: flex;
        height: 13rem;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      .parsed-headers {
        font-weight: 700;
      }
      .suggestion-table {
        width: 100%;
      }
      .select-suggestion {
        max-width: 100% !important;
      }
      .table-col-arrow {
        align-items: center;
        padding: 1rem 2rem;
      }
      .table-col-old-data {
        padding: 1rem;
        font-weight: 300;
      }

      .table-col-new-data {
        padding: 1rem;
        font-weight: 300;
      }
    }
  }
}

.accordion {
  padding: 2rem 4rem 1rem;
}

.action-block {
  display: flex;
  justify-content: space-between;
}

.help {
  display: flex;
  cursor: pointer;
}

.help-text {
  font-weight: 400;
  margin-left: 1rem;
}

.line {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-left: 0.1rem solid;
}
