
          @use "@assets/scss/_config.scss" as *;
        
.container {
  margin-top: auto;
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
