
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 36rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 7rem;

  p {
    color: #a5a5ba;
    font-size: 1.5rem;
    line-height: 1.9rem;
    margin-bottom: 1rem;
  }
}
