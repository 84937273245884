
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-direction: column;
  min-height: 53.2rem;
  max-height: 60rem;

  .last-updated {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 2.5rem;
    border-bottom: 1px solid $neutral-150;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 1.5rem;
    padding: 1rem 3rem 1rem;
    align-content: flex-start;
    overflow: auto;
    .transponder-sel {
      width: 98%;
    }
  }

  .class {
    font-size: 1.8rem;
  }

  .input {
    width: 24rem;
  }

  .accordion-header {
    display: flex;
    gap: 2rem 1.5rem;
    padding: 0;
    align-content: flex-start;
    margin-top: 1rem;
    overflow: auto;
  }
  .empty-child-tag {
    width: 100%;
    padding: 3rem 2rem;
    display: flex;
    align-items: center;

    .text {
      color: $neutral-g-900;
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-weight: 600;
    }
  }
  .accordion {
    margin: 1.8rem 0;
  }
  .multi-tag-wrapper {
    padding: 2rem;
  }
  .multi-tag {
    margin: 1.5rem 0;
    border: 1px solid $neutral-200;
    padding: 1rem;
  }
  .add-child-tag {
    margin-top: 1.1rem;
  }
  .add-toll-device {
    margin-top: 0.1rem;
  }
  .tag-consolidator {
    padding: 0.1rem;
  }
  .second-tag {
    display: flex;
    gap: 2rem 1.5rem;
    padding: 0;
    align-content: flex-start;
    margin-top: 2rem;
    overflow: auto;
  }
  .del-sec-tag {
    margin-top: 2.5rem;
  }
}

.modal {
  width: 42rem;
  .modal-header {
    padding: 0 2rem 0 3rem;
  }

  .container {
    padding: 2rem 3rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
    min-height: 1rem;
  }

  .modal-footer {
    padding: 0 2rem;
  }
}

.form::-webkit-scrollbar {
  width: 0.5rem;
  height: 100%;
}
