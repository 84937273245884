
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-items: center;
  min-height: 5.5rem;
  height: fit-content;
  padding-left: 2.5rem;
  width: 100%;
  background: $neutral-0;

  @extend %divider;

  .columns {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 1rem;
    cursor: pointer;

    > * {
      padding: 1rem 0;
    }
  }

  .context-control-wrapper {
    position: sticky;
    right: 0;
    top: 0;
    width: 10.8rem;
    background: linear-gradient(90deg, transparent 0%, $neutral-0 10%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1rem;
    flex-shrink: 0;

    .context-control {
      width: 3.2rem;

      .button {
        color: $neutral-800;
        margin-right: auto;
        padding-right: 1rem;
        width: 100%;
        height: 3.2rem;
        font-size: 2rem;
        cursor: pointer;
        flex-shrink: 0;
        @extend %inline-flex-center;
      }
    }
  }
}

.dropdown {
  width: 24rem;
}

.checked {
  background: linear-gradient(90deg, $neutral-100 50.52%, rgb(255 255 255 / 0%) 100%);
}

.checked .context-control-wrapper {
  background: linear-gradient(90deg, $neutral-100 50.52%, rgb(255 255 255 / 0%) 100%);
}

