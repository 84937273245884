
          @use "@assets/scss/_config.scss" as *;
        
@use '../../assets/column-sizes' as *;

.container {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  flex-direction: column;
  gap: 1.2rem;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    height: fit-content;
    width: 100%;
    cursor: pointer;

    .heading {
      @extend %table-label;

      text-align: left;
      white-space: wrap;
      overflow: hidden;
      text-overflow: break;
    }

    .icon {
      font-size: 1.2rem;
      cursor: pointer;
    }

    &.sort-by {
      position: relative;

      &::before {
        position: absolute;
        bottom: 2rem;
        height: 0.1rem;
        width: 1rem;
        background: $neutral-100;
        left: 0;
      }

      .icon {
        svg {
          path {
            color: $neutral-g-100;
            stroke: $neutral-g-100;
            stroke-width: 0.5px;
          }
        }

        &.asc {
          svg {
            path {
              &:first-child {
                fill: none;
              }
            }
          }
        }

        &.desc {
          svg {
            path {
              &:last-child {
                fill: none;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    .duration {
      width: 100%;
    }

    .datepicker {
      width: 100%;
      height: 3.2rem;

      & > div {
        margin-top: 0;
        border: 1px solid $neutral-g-100;

        & > div {
          background: $primary-g-700;

          input {
            background: $primary-g-700;
            caret-color: $neutral-g-100;
            color: $neutral-g-100;
            padding: 0 1rem;

            &::placeholder {
              color: #f6f6f6;
              font-weight: 400;
              text-transform: lowercase;
            }
          }

          button {
            right: 1rem;
          }
        }

        & > button {
          display: none;
        }
      }
    }

    .searchbar {
      width: 100%;
      height: 3.2rem;

      & > input {
        background: $primary-g-700;
        border: 1px solid $neutral-g-100;
        caret-color: $neutral-g-100;
        color: $neutral-g-100;

        &:focus {
          padding: 0 1rem;
        }

        &:focus ~ button {
          &[data-button-action='search'] {
            color: $neutral-g-100;
          }
        }

        &::placeholder {
          color: #f6f6f6;
          font-weight: 400;
        }
      }

      & > button {
        &[data-button-action='search'] {
          color: $neutral-g-100;

          &:hover {
            color: $neutral-g-100;
          }
        }

        &[data-button-action='clear'] {
          font-size: 1.8rem;
          right: 8.6px;

          .clear-icon {
            border-radius: 50% !important;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover svg {
              fill: $danger-600;
            }

            svg {
              fill: $neutral-g-100;

              path:first-child {
                fill: #3c5063;
              }
            }
          }
        }
      }
    }
  }
}
