
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}
