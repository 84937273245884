
          @use "@assets/scss/_config.scss" as *;
        
.sortLabel {
  display: flex;
  align-items: center;
}

.iconb {
  margin-left: 8px; // Space between label text and icon
  font-size: 1.2rem;
  cursor: pointer;

  svg {
    path {
      color: '#000000';
      stroke: '#000000';
      stroke-width: 0.5px;
    }
  }
}
.icon {
  margin-left: 8px; // Space between label text and icon
  font-size: 1.2rem;
  cursor: pointer;

  svg {
    path {
      color: $primary-600;
      stroke: $primary-600;
      stroke-width: 0.5px;
    }
  }

  &.asc {
    svg {
      path {
        &:first-child {
          fill: none; // Modify visual state for ascending order
        }
      }
    }
  }

  &.desc {
    svg {
      path {
        &:last-child {
          fill: none; // Modify visual state for descending order
        }
      }
    }
  }
}

.headinga {
  // text-align: left;
  white-space: wrap;
  overflow: hidden;
  text-overflow: break;
  color: $primary-600;
}
.heading {
  // text-align: left;
  white-space: wrap;
  overflow: hidden;
  text-overflow: break;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: 100vh !important;
}

.tableContainer::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.tableContainer::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.tableContainer::-webkit-scrollbar-thumb {
  background: #fefefe;
  border-radius: 4px;
}

.tableContainer::-webkit-scrollbar-thumb:hover {
  background: #fefefe;
}

.indicator {
  display: block;
}

@media all and (max-width: 640px) {
  .indicator {
      display: none;
  }
}