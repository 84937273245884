
          @use "@assets/scss/_config.scss" as *;
        
.container {
  padding: 1rem 1.5rem !important;
  background: $neutral-0;
  border: 1px solid $neutral-200;
  border-radius: 8px;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  z-index: 0;
  position: relative;
  overflow: hidden;

  --icon-box-width: 4rem;

  transition: all 0.3s ease;

  &[data-select-error='true'] {
    border: 1px solid $danger-600;

    .icon-container {
      border-color: $danger-600;
      color: $danger-600;
    }

    &:hover {
      border: 1px solid $danger-600;

      .icon-container {
        border-color: $danger-600;
      }
    }
  }

  .icon-container {
    width: var(--icon-box-width);
    height: 100%;
    background: $neutral-100;
    border-right: 1px solid $neutral-200;
    color: $primary-600;
    font-size: 1.6rem;
    position: absolute;
    left: 0;
    @extend %inline-flex-center;

    & ~ .container-selection {
      padding-left: var(--icon-box-width);
    }
  }

  .only-icon-container {
    width: var(--icon-box-width);
    height: 100%;
    background: $neutral-100;
    border-right: 1px solid $neutral-200;
    color: $primary-600;
    font-size: 1.6rem;
    position: absolute;
    left: -4px;
    @extend %inline-flex-center;

    & ~ .container-selection {
      padding-left: var(--icon-box-width);
    }
  }

  &:hover {
    transition: all 0.3s ease;
    border: 1px solid $primary-200;
    background: $neutral-0;

    .icon-container {
      border-color: $primary-200;
    }
  }

  &.container-active {
    border: 1px solid $primary-600;

    .icon-container {
      border-color: $primary-600;
    }
  }
}

.hover-selection {
  font-weight: 600;
  &:hover {
    background: #eaeafe;
    order: 1px solid #bfbffb;
    color: #2a28f3;
  }
}

[data-select-is-tags='true'] {
  .container {
    padding: 0.4rem;
    padding-right: 0.6rem;
  }
}

[data-select-input-disabled='true'] {
  .container {
    border: 1px solid $neutral-200;
    background: $neutral-100;
    cursor: not-allowed;

    &:hover {
      border: 1px solid $neutral-200;
      background: $neutral-100;

      .icon-container {
        border-color: $neutral-200;
      }
    }
  }
}
