
          @use "@assets/scss/_config.scss" as *;
        
@use '@assets/scss/components/navigation/section-wrapper';
@use '@assets/scss/components/navigation/nav-item-shared';

.container {
  position: relative;
  margin-top: auto;
  border-top: 1px solid $neutral-200;
  height: 6.5rem;
  width: 100%;
  @extend %inline-flex-center !optional;

  .active-customer {
    display: flex;
    justify-content: center;
    margin: auto;
    cursor: pointer;

    &.open {
      justify-content: flex-start;
    }

    picture {
      height: 4rem;
      width: 4rem;
      overflow: hidden;
      @extend %inline-flex-center;

      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .picture {
      width: 2.4rem;
      height: 2.4rem;
      margin: 0 0.8rem;
    }

    .letter-box {
      width: 2.4rem;
      height: 2.4rem;
      margin: 0 0.8rem;
      font-weight: 700;
      font-size: 1.6rem;
    }

    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
