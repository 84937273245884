
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background: $neutral-g-background;
}

.help {
  top: 4rem;
  right: 4rem;
}
