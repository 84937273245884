
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;
  gap: 1.4rem;
  flex-shrink: 0;
  position: relative;
  padding: 1.5rem 0;
}

.container::-webkit-scrollbar {
  height: 0;
}
