
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 36rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 7rem;

  p {
    color: #a5a5ba;
    font-size: 1.4rem;
    line-height: 1.7rem;

    button {
      font-size: 1.4rem;
      line-height: 1.7rem;
      display: inline-block;
      width: fit-content;
      color: $primary-600;
    }
  }
}
