
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  padding: 1rem 1.6rem;
  list-style: none;
  text-transform: capitalize;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: $neutral-100;
    border-radius: 4px;
  }

  &.selected {
    font-weight: 600;
    color: $primary-600;

    .approve {
      font-size: 2rem;
    }
  }

  &[data-is-tags='true'] {
    justify-content: flex-start;
    gap: 0.8rem;
    padding: 0.8rem;

    .checkbox {
      width: 2rem;
      height: 2rem;
      border-radius: 5px;
      font-size: 1.7rem;
      background: $neutral-0;
      border: 1px solid $neutral-300;
      @extend %inline-flex-center;
    }

    &.selected {
      .checkbox {
        background: $primary-600;
        color: $neutral-0;
        border: 0;
      }
    }
  }
}
