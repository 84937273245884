
          @use "@assets/scss/_config.scss" as *;
        
.container {
  padding: 0rem 3rem 0rem 3rem;
  height: 60rem;
  overflow: auto;

  .list {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin-top: 2rem;

    & > li {
      width: 100%;
    }
  }
}

.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.accordion {
  width: 100%;

  .content {
    padding: 1.6rem 1.6rem 1.6rem 5.6rem;
    overflow-y: auto;
    display: flex;
    align-items: center;

    div {
      width: 18rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-right: 8rem;
    }

    h3 {
      font-weight: 700;
      font-size: 1.1rem;
      line-height: 1.3rem;
      text-transform: uppercase;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
}
