
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 36rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 7rem;
  background: #fff;
  border-radius: 10px;
  border: 1px solid transparentize($color: #a5a5ba, $amount: 0.7);

  p {
    color: #a5a5ba;
    font-size: 1.4rem;
    line-height: 1.7rem;

    button {
      font-size: 1.4rem;
      line-height: 1.7rem;
      display: inline-block;
      width: fit-content;
      color: $primary-600;
    }
  }
}
