
          @use "@assets/scss/_config.scss" as *;
        
.container {
  margin-top: 1.7rem;

  .cell {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-top: 1px solid $neutral-g-200;
    padding: 2rem 0;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;

      & > p {
        width: 45%;
      }

      .header {
        text-transform: capitalize;
        @extend %body-highlight;
      }
    }
  }
}
