
          @use "@assets/scss/_config.scss" as *;
        
.container {
  padding: 2rem;
  ::-webkit-scrollbar {
    width: 2px !important;
  }
  ::-webkit-scrollbar-horizontal {
    height: 2px; /* Set your desired height for horizontal scrollbar */
  }
  .help {
    position: absolute;
    right: -4.5rem;
    top: 1.5rem;
    font-size: 2.2rem;
    color: #32324d !important;
  }
  .header {
    position: relative;
    display: inline-block;
    .fwd-arrow {
      padding-left: 0.5rem;
      svg {
        vertical-align: middle;
      }
    }
  }
  .sub-header {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.2rem;
    letter-spacing: 0px;
    text-align: left;
    color: #8e8ea9;
    margin-bottom: 1rem;
  }
}
.border-margin {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.top-border {
  padding: 2rem !important;
  border-top: 4px solid $tertiary-g-500;
}
