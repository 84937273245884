
          @use "@assets/scss/_config.scss" as *;
        
@use '@assets/scss/components/navigation/nav-item-shared';

.nav-item {
  cursor: pointer;

  .icon {
    width: 4rem !important;
    height: 100%;
    border: 0 !important;
    position: relative;
    @extend %inline-flex-center;

    &.notifications-icon {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background: $danger-600;
        border: 1px solid $neutral-0;
        top: -0.2rem;
        right: 0.6rem;
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }
  }

  .text {
    .text-notifications {
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 700;
      color: $neutral-0;
      background: $danger-600;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      margin-left: auto;
      @extend %inline-flex-center;
    }
  }
}
