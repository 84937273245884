
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem 1.5rem;
  padding: 3rem 3rem 4rem;
  min-height: 53.2rem;
  max-height: 70rem;
  align-content: flex-start;
  overflow: auto;

  .input {
    width: 23rem;
  }
}

.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
