
          @use "@assets/scss/_config.scss" as *;
        
.container {
  .label,
  .disabled-input,
  .error-description {
    padding: 0 1.2rem;
    text-align: left;
  }

  .label {
    font-weight: 600;
    @extend %small-text;
  }

  .group {
    display: flex;
    gap: 1.3rem;

    .input {
      flex-grow: 1;
      width: 50%;
    }
  }
}
