
          @use "@assets/scss/_config.scss" as *;
        
@use './assets/scss/config' as *;
@import-normalize;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  scroll-behavior: smooth;
  font-family: $primary-font;
  color: $neutral-800;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  min-height: 100vh;
  @extend %body;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
  }

  li {
    list-style: none;
  }

  button {
    border: 0;
    font-family: inherit;
    cursor: pointer;
    background: none;
    color: inherit;

    &:focus,
    &:focus-visible {
      outline: $remove-outline;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  input {
    &:focus-within {
      outline: $remove-outline;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
}

::-webkit-scrollbar-thumb {
  background: $neutral-800;
}
.sash-vertical {
  background-color: #d2d2d2;
  width: 0.3rem !important;
  margin-left: 0.9rem !important;
}
