
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .display-container {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: $neutral-g-900;
  }

  .columns-control {
    color: $secondary-g-700;
    display: none;

    @media screen and (max-width: $screen-l) {
      display: block;
    }

    & > button {
      @extend %small-button-text;
    }
  }

  @media screen and (max-width: $screen-s) {
    justify-content: flex-end;

    p {
      display: none;
    }
  }
}
