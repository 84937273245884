
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  text-align: left;
  margin-left: 0.6rem;
  display: flex;
  align-items: center;
  gap: 1.4rem;
}
