
          @use "@assets/scss/_config.scss" as *;
        
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
}

.notify-container {
  margin-bottom: 3rem;
}

.switch-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 5rem;
  margin-top: 2rem;
  border: 0.1rem solid #dcdce4;
  padding: 1rem;
  border-radius: 1rem;
  margin-right: 5rem;
  transition: all 0.5s;
}

.switch-container:hover {
  background: #eeeef2;
  transition: all 0.5s;
}

.text-style {
  margin-left: 1rem;
  font-weight: 600;
  font-size: small;
  align-self: center;
}

.heading {
  margin-left: 4rem;
  margin-top: 2rem;
}
