
          @use "@assets/scss/_config.scss" as *;
        
.container {
  height: 4rem;
  width: 18rem;
  border: 8px;
  @extend %flex-center;

  & > * {
    width: 50%;
    height: 100%;
    color: $neutral-600;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    border: 1px solid $neutral-200;
    background: $neutral-0;
    cursor: pointer;
    @extend %inline-flex-center;
  }

  &[data-toggle-state='true'] {
    [data-toggle-label='false'] {
      border-left-color: $success-200;
    }
  }

  &[data-toggle-state='false'] {
    [data-toggle-label='true'] {
      border-right-color: $danger-200;
    }
  }

  [data-toggle-label='true'] {
    border-right-width: 0.5px;
    border-radius: 8px 0 0 8px;

    &.active {
      background: $success-100;
      border: 1px solid $success-200;
      color: $success-600;
    }
  }

  [data-toggle-label='false'] {
    border-left-width: 0.5px;
    border-radius: 0 8px 8px 0;

    &.active {
      background: $danger-100;
      border: 1px solid $danger-200;
      color: $danger-600;
    }
  }

  &[data-toggle-disabled] {
    & > * {
      background: $neutral-100;
      cursor: not-allowed;
    }

    .true,
    .false {
      background: $neutral-200;
      border: 1px solid $neutral-300;
      color: $neutral-600;
    }
  }
}
