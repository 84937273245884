
          @use "@assets/scss/_config.scss" as *;
        
.container {
  height: 8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $neutral-200;

  .logo {
    picture:first-child {
      height: 4rem;
      width: 4rem;
    }
  }
}
