
          @use "@assets/scss/_config.scss" as *;
        
.body {
  width: 52.6rem;
  padding: 4rem 4.7rem;
}

.container {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 3.2rem;
    @extend %header2;
  }
}
