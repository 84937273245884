
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  list-style: none;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.97rem;
  padding: 0.97rem;
  text-align: left;

  &:hover {
    background: $neutral-100;
    border-radius: 4px;
  }

  &.export,
  &.edit,
  &.view {
    color: $primary-600;
  }

  &.delete {
    color: $danger-600;
  }

  .item-icon {
    font-size: 2rem;
    @extend %inline-flex-center;
  }
}
