
          @use "@assets/scss/_config.scss" as *;
        
.wrapper {
  max-height: 40rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;

  &.with-scrollbar {
    margin-right: 1rem;
  }
}

.modal {
  margin: 7rem 5rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.wrapper::-webkit-scrollbar {
  width: 12px;
}

.wrapper::-webkit-scrollbar-track {
  margin-block: 2rem;
  background: $neutral-150;
  border-radius: 6px;
  position: relative;
  background-clip: padding-box;
}

.wrapper::-webkit-scrollbar-thumb {
  border-radius: 11px;
  background: $neutral-0;
  border: solid $neutral-150;
  border-width: 8px 2px;
  background-clip: padding-box;
}

.select-container {
  display: flex;
  flex-direction: row;
  margin-left: 5rem;

  .toggle-span {
    display: flex;
    margin-left: 5rem;
  }

  p {
    font-weight: 600;
    margin-top: 0.5rem;
    margin-right: 1rem;
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
