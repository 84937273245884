
          @use "@assets/scss/_config.scss" as *;
        
.container {
  .updated-by {
    margin-bottom: 0.8rem;
  }

  .acc-title {
    font-size: 1.4rem !important;
    font-weight: 600;
  }

  .closed-accordion {
    margin-top: auto;
    transition: all 1s ease-in-out;
  }

  .open-accordion {
    margin-top: auto;
    transition: all 1s ease-in-out;
  }

  .acc-summary {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    width: 85%;
    font-size: 1.3rem !important;
    font-weight: 600 !important;

    .expense-total {
      text-align: start;
    }

    .expense-percent {
      flex-shrink: 0;
      text-align: end;
    }
  }

  .sub-types {
    font-size: 1.1rem !important;
    border-left: 2px solid #dcdce4;
    // padding-left: 5rem;
    margin-left: 5rem;

    // margin-right: 0rem;
    .label-grid {
      // padding-left: 5rem;
      display: flex;
      align-items: center;
    }

    .amount {
      font-weight: 600 !important;
      text-align: left;
    }

    .percent {
      font-weight: 600 !important;
      text-align: right;
    }
  }

  .correction {
    font-weight: 600;

    .amount {
      font-size: 1.3rem !important;
      text-align: left;
    }

    .percentage {
      font-size: 1.4rem !important;
      text-align: right;
    }

    .label {
      font-size: 1.4rem !important;
    }
  }

  .divider {
    margin-top: 1rem;
  }

  .breakout {
    // margin-left: 6rem;
    margin-top: 1rem;

    .actual-toll-bill {
      font-weight: 600;
    }

    .managed-toll-bill {
      margin-top: 1rem;
      color: #666687;
    }
  }

  .legend-box {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
}
