
          @use "@assets/scss/_config.scss" as *;
        

.skeletonContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 50rem;
    width: 100%;
}

.skeletonBar {
    width: 20px;
    height:100%;
    margin-right: 10px;
    background-color: lightgrey;
}