
          @use "@assets/scss/_config.scss" as *;
        
.container {

  .main {
    align-items: center;
  }
  .header {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 3.9rem;
    padding-top: 1.7rem;
  }
  .body {
    height: 45rem;
    width: calc(100% - 150px);
    margin: 1.5rem auto 0;
    border-top: 0.2rem solid #dcdce4;
    padding: 1.4rem 0;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.9rem;
    overflow: auto;
  }

  .body body {
    min-height: 100%;
    padding: 2rem;
  }
}
