
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  font-size: 1.2rem;
  line-height: 1.5rem;

  .label {
    color: $neutral-500;
  }

  .display-container {
    width: 6rem;
    min-width: fit-content;
    height: 3.2rem;
    position: relative;
    font-weight: 600;

    .display-container-box {
      padding: 0 0.5rem 0 1.2rem;
      background: $neutral-0;
      border: 1px solid $neutral-150;
      border-radius: 4px;
      width: 6rem;
      min-width: fit-content;
      height: 3.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      z-index: 0;
      font-weight: 600;

      &:hover {
        border: 1px solid $neutral-150;
        background: $neutral-100;
      }

      &.display-container-box-active {
        color: $primary-600;

        .icon {
          color: $primary-600;
        }
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $neutral-500;
        font-size: 1.7rem;
        position: relative;
        z-index: 1;

        .arrow {
          &.arrow-open {
            transform: rotate(-180deg);
          }
        }
      }
    }

    &[data-disabled='true'] {
      .display-container-box {
        background: $neutral-150;
        cursor: not-allowed;

        &:hover {
          border: 1px solid $neutral-150;
          background: $neutral-150;
        }

        .value {
          color: $neutral-600;
        }
      }
    }
  }
}

.dropdown {
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid $neutral-150;
  background: $neutral-0;
  padding: 0;
  width: 6rem;
  color: $neutral-800;

  li {
    button {
      padding: 0.6rem 1rem;

      &:hover {
        border-radius: 2px;
      }

      svg {
        display: none;
      }
    }
  }
}
