
          @use "@assets/scss/_config.scss" as *;
        
.container {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .table-wrapper {
      overflow: auto;
      margin-left: 4rem;
      padding-right: 4rem;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;
      height: 20rem;
      flex-grow: 1;

      .table {
        height: fit-content;
        color: $neutral-g-900;
        width: 100%;
        background: $neutral-g-100;
        margin-bottom: 1.6rem;
        border: 1px solid $neutral-g-300;
        border-top: 0;
        border-collapse: collapse;
      }
    }
  }
}

.table-wrapper::-webkit-scrollbar {
  background: $neutral-g-300;
  height: 20px;
  width: 0;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: $tertiary-g-600;
  border-block: 3px solid $neutral-g-300;
  border-inline: 7px solid $neutral-g-300;
  background-blend-mode: multiply;
}
