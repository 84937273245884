
          @use "@assets/scss/_config.scss" as *;
        
.container {
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.8rem;
  line-height: 2rem;
  flex-grow: 1;
  display: flex;
  gap: 1ch;
  padding-top: 4rem;

  span {
    font-weight: 600;
  }
}
