
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 23rem;
  position: relative;

  label {
    font-weight: 600;
    @extend %small-text;

    text-transform: capitalize;
    padding-left: 1.2rem;
    z-index: 0;
  }

  &.container-error {
    .error-description {
      padding-left: 1.2rem;
      margin-top: 0.4rem;
      color: $danger-600;
      @extend %small-text;
    }
  }
}

.dropdown {
  width: 23rem;
}
