
          @use "@assets/scss/_config.scss" as *;
        
.modal {
  overflow-x: hidden;
  width: 64rem;
}

.container {
  height: 64.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .toll-document {
    width: 43.6rem;
    height: 57.5rem;
    display: inline-block;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
