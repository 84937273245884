
          @use "@assets/scss/_config.scss" as *;
        
.container {
  height: 100%;
  @extend %flex-center;

  .loader {
    margin-top: -10rem;
    width: 50px;
    height: 50px;
    border-width: 7px;
  }
}
