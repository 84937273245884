
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  background: $neutral-0;

  .summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border: 1px solid $neutral-200;
    border-radius: 8px;
    padding: 1.2rem 0.8rem;
    cursor: pointer;
    transition: all 0.2s ease-in;

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1.6rem;

      .icon {
        width: 2.4rem;
        height: 2.4rem;
        background: $neutral-200;
        border-radius: 50%;
        font-size: 1.6rem;
        transition: all 0.2s ease-in;
        @extend %inline-flex-center;
      }

      h3 {
        text-transform: capitalize;
        @extend %body-highlight;
      }
    }

    .icon-group {
      display: flex;
      align-items: center;
      gap: 1rem;

      li {
        list-style: none;
        border-radius: 4px;

        button {
          font-size: 1.6rem;
          width: 2.6rem;
          height: 2.6rem;
          @extend %inline-flex-center;
        }

        &:hover {
          background: $neutral-200;
        }
      }
    }
  }

  &.open {
    .summary {
      border-radius: 8px 8px 0 0;
      border-color: $primary-200;
      background: $primary-100;
      color: $primary-600;
      transition: all 0.2s ease-in;

      &:hover {
        background: $primary-100;
      }

      .title {
        color: $primary-600;

        .icon {
          background: $primary-200;
          transform: rotate(-180deg);
          transition: all 0.2s ease-in;
        }
      }

      .icon-group {
        li {
          button {
            color: $primary-600;
          }

          &:hover {
            background: $primary-200;
          }
        }
      }
    }
  }

  .content {
    border: 1px solid $neutral-200;
    border-top: 0;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }
}
