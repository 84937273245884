
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 72rem;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid $neutral-200;
  font-size: 1.4rem;
  line-height: 1.7rem;

  .action {
    gap: 0.7rem;
    @extend %inline-flex-center !optional;

    .author {
      color: $primary-600;
      text-transform: capitalize;
      font-weight: 600;
    }

    .type {
      font-weight: 400;
      color: $neutral-600;
    }

    .item {
      text-transform: capitalize;
      margin-left: 0.2rem;
      color: $neutral-700;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem;
      font-weight: 600;

      .icon {
        color: $neutral-800;
        margin-right: 0.1rem;
        display: flex;
        align-items: center;
      }
    }
  }

  .info {
    margin-top: 0.8rem;
    display: flex;
    justify-content: space-between;

    > * {
      width: 60%;
    }

    .change,
    .create {
      text-transform: capitalize;
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;
      color: $neutral-800;
      font-weight: 600;

      .arrow {
        display: flex;
        align-items: center;
        transform: rotate(180deg);
        font-size: 1.6rem;
      }
    }

    .date {
      font-size: inherit;
      line-height: inherit;
      color: $neutral-500;
      text-align: right;
    }
  }
  .mobile-info {
    margin-top: 0.8rem;
    display: block;
    align-items: center;
    justify-content: space-between;

    > * {
      width: 100%;
      margin-bottom: 1rem;
    }

    .change,
    .create {
      text-transform: capitalize;
      display: flex;
      gap: 0.8rem;
      color: $neutral-800;
      font-weight: 600;

      .arrow {
        display: flex;
        align-items: center;
        transform: rotate(180deg);
        font-size: 1.6rem;
      }
    }

    .date {
      font-size: inherit;
      line-height: inherit;
      color: $neutral-500;
      text-align: right;
    }
  }
}
