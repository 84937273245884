
          @use "@assets/scss/_config.scss" as *;
        
.container {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .wrapper {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.warning {
    .content {
      .heading {
        color: $warning-700;
      }
    }
  }

  .background {
    position: absolute;
    left: -5rem;
    bottom: 9rem;
    z-index: 0;
    opacity: 0.2;

    picture {
      width: 35rem;
      height: 35rem;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    text-align: center;
    color: $neutral-800;
    font-size: 1.6rem;
    line-height: 1.9rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .payment-info {
      padding: 1rem;
      line-height: 2.6rem;
    }

    .logo {
      picture {
        width: 8rem;
        height: 8rem;
      }
    }

    .heading {
      margin: 3rem 0 2rem;
      color: $secondary-g-700;
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.6rem;
    }

    .second-heading {
      color: $secondary-g-700;
      font-size: 3rem;
      font-weight: 500;
      line-height: 4.6rem;
      margin-top: -2rem;
    }

    .email-text {
      margin-top: 0.5rem;
      .email-resend {
        cursor: pointer;
        color: #0078d3;
      }
    }

    .sub-heading {
      margin-bottom: 0.6rem;
      margin-top: 1.5rem;
      line-height: 2.5rem;

      .account-id {
        font-weight: 600;
      }

      .subscription {
        font-weight: 300;
      }
    }
  }
}
