
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: inline-flex;
  gap: 0.6rem;
  overflow: auto;
  flex-grow: 1;
  height: 100%;
  min-width: fit-content;
  
  &.with-scrollbar {
    padding-bottom: 0.4rem;
  }
}

.container::-webkit-scrollbar {
  width: 0;
  height: 12px;
}

.container::-webkit-scrollbar-track {
  background: $neutral-150;
  border-radius: 6px;
  position: relative;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 11px;
  background: $neutral-0;
  border: solid $neutral-150;
  border-width: 2px 8px;
  background-clip: padding-box;
}
