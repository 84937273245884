
          @use "@assets/scss/_config.scss" as *;
        
.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 12rem;
  height: 100%;
  cursor: pointer;

  &.datacontainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &.fleet-vehicle-axes,
  &.fleet-vehicle-tires,
  &.fleet-width,
  &.fleet-height,
  &.trips-distance,
  &.trips-time-spent,
  &.trips-money-spent,
  &.toll-document-amount {
    width: 10rem;
  }

  &.toll-documents-status {
    width: 16rem;
  }

  &.transactions-base-state,
  &.transactions-plate-number,
  &.transactions-date {
    width: 14rem;
  }

  &.trips-start-point,
  &.trips-end-point,
  &.trips-start-date,
  &.trips-end-date,
  &.roles-name,
  &.toll-documents-toll-document-date,
  &.toll-document-tag-transponder {
    width: 16rem;
  }

  &.toll-documents-toll-document-number {
    width: 17rem;
  }

  &.transactions-plaza,
  &.transactions-facility,
  &.customers-status,
  &.users-status,
  &.toll-document-transaction-date-time,
  &.users-roles {
    width: 20rem;
  }

  &.toll-document-description-location {
    width: 22rem;
  }

  &.fleet-vehicle-class,
  &.fleet-tag-transponder,
  &.transactions-tag-transponder,
  &.transactions-toll-agency,
  &.customers-company-name,
  &.transactions-status,
  &.users-name {
    width: 24rem;
  }

  &.fleet-vin {
    min-width: 18rem;
    overflow-wrap: break-word;
  }

  &.toll-documents-invoice-issuer,
  &.toll-documents-toll-document-name {
    min-width: 20rem;
    overflow-wrap: break-word;
  }

  &.customers-admin-e-mail-address,
  &.customers-address,
  &.users-email-address {
    max-width: 20rem;
    overflow-wrap: break-word;
  }

  &.roles-users {
    width: 40rem;

    .data {
      color: $primary-600;
      font-weight: 600;
    }
  }

  &.roles-description {
    width: 50rem;
  }

  &.sort-by {
    color: $primary-600;

    .icon {
      svg {
        path {
          color: $primary-600;
          stroke: $primary-600;
          stroke-width: 0.5px;
        }
      }

      &.asc {
        svg {
          path {
            &:first-child {
              fill: none;
            }
          }
        }
      }

      &.desc {
        svg {
          path {
            &:last-child {
              fill: none;
            }
          }
        }
      }
    }
  }

  .heading {
    @extend %table-label;

    text-align: left;
    white-space: wrap;
    overflow: hidden;
    text-overflow: break;
  }

  .data {
    white-space: wrap;
    overflow: hidden;
    text-overflow: break;
  }

  .data-red {
    color: red;
  }

  .data-grey {
    color: #B3B3B3;
  }

  .data-green {
    color: #02b502; // green;
  }

  .search-highlight {
    display: inline-block;
    background: $primary-100;
    border-radius: 2px;
  }

  &.not-found {
    color: black;
  }

  .icon {
    font-size: 1.2rem;
    cursor: pointer;
  }

  .signal-icon {
    display: flex;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
  }
}
