
          @use "@assets/scss/_config.scss" as *;
        
.container {
  background: $neutral-0;
  border: 1px solid $neutral-150;
  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  width: 100%;
  height: 6.4rem;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .default-buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: auto;
  }
}
