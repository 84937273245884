
          @use "@assets/scss/_config.scss" as *;
        
.body {
  padding: 4rem 4.8rem;
  width: 62rem;

  &.form {
    width: 48rem;
    padding: 4rem;
  }

  picture {
    padding-top: 3.7rem;
  }
}

.container {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 2.5rem;
    @extend %header2;
  }

  &.form {
    padding-top: 3rem;
  }

  & > p {
    height: 7rem;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;

    span {
      display: inline-block;
    }
    @extend %subtitle;
  }

  & > button {
    width: 40rem;
    margin-block: 3rem 2rem;
    @extend %small-button-text;
  }

  & > small {
    font-size: 1.4rem;
    line-height: 1.7rem;

    & > button {
      font-size: 1.4rem;
      line-height: 1.7rem;
      display: inline-block;
      width: fit-content;
      color: $primary-600;
    }
  }
}
