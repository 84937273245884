
          @use "@assets/scss/_config.scss" as *;
        
.container {
  height: 4rem;
  width: 30rem;
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.2s width linear;
  flex-shrink: 0;

  &.collapsed {
    width: 4rem;
    transition: 0.2s width linear;

    &[data-searchbar-size='xs'] {
      width: 3.2rem;

      .input {
        padding: 0;
      }
    }

    .input {
      padding: 0;
      pointer-events: none;

      &:focus {
        padding: 0;
      }
    }

    button {
      &.search {
        background: $neutral-0;
        z-index: 1;
        border: 1px solid $neutral-200;
        border-radius: 8px;
        width: 100%;
      }
    }
  }

  &[data-searchbar-size='xs'] {
    height: 3.2rem;

    .input {
      font-size: 1.2rem;
      line-height: 1.5rem;
      padding: 0 1rem 0 3.1rem;

      &[data-search-icon='false'] {
        padding: 0 1rem;
      }
    }

    button {
      &.search {
        width: 3.2rem;
      }

      &.clear {
        right: 8.6px;
      }
    }
  }

  .input {
    background: $neutral-0;
    border: 1px solid $neutral-200;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    font-size: 1.4rem;
    line-height: 1.7rem;
    caret-color: $neutral-500;
    padding: 0 1rem 0 3.8rem;
    font-family: $primary-font;

    &[data-search-icon='false'] {
      padding: 0 1rem;

      &:focus {
        padding: 0 0.9rem;
      }
    }

    &::placeholder {
      color: $neutral-500;
    }

    &:focus {
      outline: 0;
      border: 2px solid $primary-600;
      padding: 0 0.9rem 0 3.7rem;

      & ~ .search {
        color: $primary-600;
      }
    }
  }

  button {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: fit-content;

    &.search {
      left: 0;
      font-size: 2rem;
      width: 4rem;
      height: 100%;

      &:hover,
      &.active {
        color: $primary-600;
      }
    }

    &.clear {
      right: 11.6px;
      font-size: 1.5rem;

      &:hover {
        color: $danger-600;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
