
          @use "@assets/scss/_config.scss" as *;
        
.container-wrapper {
  position: relative;
  width: 100%;
}

.container {
  width: 100%;
  height: 4.8rem;
  background: $neutral-0;
  border: 1px solid $neutral-200;
  border-radius: 8px;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  &.dragging {
    .drag-box {
      cursor: grabbing;
    }

    z-index: 1;
    background: $neutral-100;
  }

  &:hover {
    background: $neutral-100;
  }

  .title {
    padding: 1.2rem 1.6rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    gap: 1.6rem;

    & > h3 {
      @extend %body-highlight;
    }
  }

  .drag-box {
    padding: 0 1rem;
    width: 4.4rem;
    height: 100%;
    font-size: 2.2rem;
    color: $neutral-600;
    cursor: grab;
    @extend %inline-flex-center;

    &::before {
      content: '';
      position: absolute;
      width: 1px;
      top: 0;
      bottom: 0;
      right: 4.4rem;
      background: $neutral-200;
    }
  }
}
