
          @use "@assets/scss/_config.scss" as *;
        
.wrapper {
  padding-bottom: 0.5rem;
  overflow: hidden;
  background: $neutral-0;
  border: 1px solid $neutral-g-300;
  border-radius: 8px;
  flex-grow: 1;
  height: 40vh;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;

  @media screen and (max-width: $screen-s) {
    border: none;
    overflow: visible;
    height: auto;
  }

  .container {
    width: 100%;
    overflow: auto;
    position: relative;
    flex-grow: 1;
    padding-bottom: 2rem;
    border-radius: inherit;
    display: grid;
    align-content: start;
  }

  .infinite-loader {
    width: 30px;
    height: 30px;
    border-width: 4px;
    margin: 0 auto;
  }

  .loader {
    margin-top: 4rem;
    width: 40px;
    height: 40px;
    border-width: 5px;
    place-self: center;
    display: inline-block;
  }
}

.cust-wrapper-ht {
  height: calc(100% - 48px) !important;
}

.container::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}

.container::-webkit-scrollbar-track {
  margin-inline: 0.8rem;
  margin-block: 0.8rem;
  background: $neutral-150;
  border-radius: 6px;
  position: relative;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 11px;
  background: $primary-g-700;

  // border: solid $neutral-150;

  // border-width: 2px 8px;
  background-clip: padding-box;
}
