
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  position: relative;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  background: $neutral-0;
  @extend %divider;

  form {
    display: none;
  }

  &.container-header {
    position: -webkit-sticky;
    position: sticky;
    background: $neutral-0;
    width: 100%;
    z-index: $table-header-z-index;
    top: 0;
  }

  &.container-data {
    cursor: pointer;

    &.checked {
      background: $neutral-100;

      .wrapper {
        background: $neutral-100;
      }

      &::before {
        background: transparent;
      }
    }
    &.highlight {
      background: $neutral-100;
    }
  }
}
