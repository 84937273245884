
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;

  picture {
    display: inline-block;
    position: relative;

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }

  .logo {
    width: 4rem;
    height: 4rem;
  }

  .logo-with-text {
    width: 24.7rem;
    height: 4rem;
  }
}
