
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 20px;
  height: 20px;
  aspect-ratio: 1;
  border-radius: 50% !important;
  border: 4px solid $neutral-200;
  border-right-color: $primary-600;
  animation: s2 1s infinite linear;
}
@keyframes s2 {
  to {
    transform: rotate(1turn);
  }
}
