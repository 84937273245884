
          @use "@assets/scss/_config.scss" as *;
        
.control {
  width: 3.2rem;
  height: 3.2rem;
  font-size: 2rem;
  cursor: pointer;
  flex-shrink: 0;
  @extend %inline-flex-center;
}

.dropdown {
  width: 24rem;
}
