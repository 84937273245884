
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
}

.leftComponent {
  width: 55%;
  padding: 2rem;
}

.rightComponent {
  width: 45%;
}

.divider {
  border-right: 5px solid #faf9f9;
}

.rightContainer {
  margin-top: 2rem;
  width: 100%;
  display: grid;
  background: #fff;
  border: 1px solid #eaeaef;
  border-radius: 8px;
}

.header {
  width: 100%;
}

.header h3 {
  @extend %table-label;
  font-weight: bold;
  text-align: left;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  // color: #3c5063;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 450px);
}

.row {
  width: 100%;
  height: 3.3rem;
  display: flex;
  align-items: center;
  padding: 3rem 0;
  position: relative;
}

.row::before {
  position: absolute;
  content: '';
  background: $neutral-200;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}

.text {
  display: grid;
  grid-template-columns: minmax(10rem, 15rem) minmax(18rem, auto) minmax(5rem, 6rem) minmax(
      8rem,
      9rem
    );
  align-items: center;
}

.text h3:nth-child(3) {
  margin-left: -1rem;
}

.text h3,
.text p {
  text-align: left;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
}
p {
  font-size: small;
}

.text input[type='checkbox'] {
  margin-left: auto;
  margin-right: 1rem;
}

#name {
  grid-column: 1 / 2;
}

#vin {
  grid-column: 2 / 3;
}

#state {
  grid-column: 3 / 4;
  margin-left: -0.5rem;
}

#licensePlate {
  grid-column: 4 / 5;
}

.billSummary {
  text-align: center;
}

.section {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section h4 {
  font-size: 1.2rem;
  color: #333;
  flex: 1;
  text-align: left;
  margin-right: 1rem;
}

.count,
.amount {
  font-size: 1.5rem;
  color: #777;
  text-align: right;
  flex: 1;
}

.billDivider {
  border: none;
  height: 2px;
  background-color: #ddd;
  margin: 1rem 0;
}

.amount {
  font-weight: bold;
  color: #333;
}

.summary {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 3rem 2rem;

  .summary-wrapper {
    // border: 1px solid #25477b;
    width: 60rem;
    // height: 30rem;
    overflow-x: auto;
    border-radius: 8px;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.19), 0 1px 6px rgba(0, 0, 0, 0.23);
    .summary-heading {
      text-align: center;
      font-size: 1.7rem;
      padding-bottom: 1rem;
      // border-bottom: 0.2rem #e2e2e2 solid;
      background: #25477b;
      color: #fff;
      padding: 1.5rem 2rem;
    }
    .summary-body {
      margin-top: 1rem;
      .total-before-tax {
        border-top: 1px solid #dcdce4;
        padding-top: 0.5rem;
        // padding-bottom: ;
      }
      .monthly-rate {
        padding-top: 1rem;
        border-top: 1px solid #dcdce4;
      }
      .tax-value {
        font-size: 1.2rem;
        color: #009f00;
        padding: 0.3rem;
        // transform: translateY(-0.6rem);
      }
      .total {
        padding-top: 0.4rem;
        border-top: 1px solid #dcdce4;
        font-weight: 800;
      }
      ul {
        padding: 0px 1.5rem;
        li {
          display: flex;
          justify-content: space-between;
          padding-bottom: 2rem;
          padding-top: 0.5rem;
          font-size: 1.6rem;
        }
      }
    }
  }
  .summary-wrapper:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}
