
          @use "@assets/scss/_config.scss" as *;
        
.container {
  padding: 2rem 3rem;
  height: 53.2rem;
  overflow: auto;

  .histories {
    margin-top: 2.6rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    & > li {
      width: 100%;

      .log {
        width: 100%;
      }
    }
  }
}

.container::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
}
