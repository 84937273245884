
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 36rem;
  box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
  background: $neutral-0;
  border: 1px solid $neutral-150;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% + 0.8rem);
  display: flex;
  flex-direction: column;
  z-index: $filter-dropdown-z-index;

  @media screen and (max-width: $screen-s) {
    position: relative;
    box-shadow: none;
    height: auto;
    margin-top: 1.7rem;
    width: 100%;
  }

  .body {
    padding-top: 2.2rem;

    @media screen and (max-width: $screen-s) {
      padding-bottom: 2rem;
    }

    .heading {
      padding: 0 2rem 1rem;
      margin-left: 1.2rem;
      @extend %header2;
    }

    .inputs-container {
      padding: 0 2rem 8rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 30rem;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.input {
  width: 100% !important;
}

.inputs-container::-webkit-scrollbar {
  width: 1rem;
}
