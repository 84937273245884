
          @use "@assets/scss/_config.scss" as *;
        
.container {
  position: absolute;
  width: 100%;
  bottom: 1px;
  transform: scale(1);

  --slider-thumb-width: 8px;
  --slider-thumb-height: 8px;

  &[data-slider-disabled='true'] {
    .range {
      background: $neutral-200;
    }

    .thumb::-webkit-slider-thumb {
      border: 2px solid $neutral-200;
    }
  }

  span {
    display: flex;
    align-items: center;
  }

  .range {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    background: $primary-600;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;

    &.thumb1 {
      z-index: 3;

      &.increase-index {
        z-index: 5;
      }
    }

    &.thumb2 {
      z-index: 4;
    }
  }
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb::-webkit-slider-thumb {
  background: $neutral-0;
  border-radius: 50%;
  cursor: pointer;
  width: var(--slider-thumb-width);
  height: var(--slider-thumb-height);
  margin-top: calc((var(--slider-thumb-height) / 2) - (var(--slider-height)));
  pointer-events: all;
  position: relative;
  border: 2px solid $primary-600;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background: $neutral-0;
  border-radius: 50%;
  cursor: pointer;
  width: var(--slider-thumb-width);
  height: var(--slider-thumb-height);
  margin-top: calc((var(--slider-thumb-height) / 2) - (var(--slider-height)));
  pointer-events: all;
  position: relative;
  border: 2px solid $primary-600;
}
