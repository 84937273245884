
          @use "@assets/scss/_config.scss" as *;
        
.container {
  background: $neutral-0;
  border: 1px solid $neutral-200;
  border-radius: 8px;
  padding: 4rem;
  width: 48rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .logo {
    margin-inline: auto;
    margin-bottom: 3rem;
    justify-content: center;

    picture:first-child {
      width: 6rem;
      height: 6rem;
    }
  }

  h1 {
    font-weight: 800;
    font-size: 8.3rem;
    line-height: 10rem;
    color: $primary-600;
    margin-bottom: 1rem;

    &.no-zero {
      letter-spacing: 20px;
    }

    img {
      width: 6.2rem;
      height: 6.2rem;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.6rem;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: $neutral-400;
  }

  a:last-child {
    width: 100%;
  }

  button {
    margin-top: 4rem;
    width: 100%;
  }
}
