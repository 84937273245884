
          @use "@assets/scss/_config.scss" as *;
        
.tabs {
    margin-top: 5.1rem;
    width: 100%;
    height: 100%;
    flex-grow: 1;

    &>article {
        height: 100%;
    }
}

.refresh-wrapper {
    text-align: end;
    margin-bottom: 0.3rem;
}



.container {
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
    
.container::-webkit-scrollbar {
    display: none;
}
    
.loaderht {
    text-align: center;
    margin-top: 250px;
}