
          @use "@assets/scss/_config.scss" as *;
        
.container {
  height: 8rem;
  background: $tertiary-g-600;
  padding: 0 3rem;
  border-bottom: 1px solid $neutral-g-300;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  .steps {
    height: 100%;
    display: flex;
    gap: 1rem;

    li {
      height: 100%;
      padding: 0.6rem 1rem 0;
      display: flex;
      align-items: center;
      gap: 0.8rem;
      width: fit-content;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: $neutral-g-300;

      &.active {
        color: $neutral-g-100;
        background: $tertiary-g-500;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background: $neutral-g-100;
        }
      }
      .step-label{
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #293657;
        border-radius: 20px;
        font-size: 14px;
      }
    }

    .text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;

      .heading {
        font-weight: 600;
      }
    }

    .help {
      position: relative;
      top: unset;
      right: unset;
      flex-shrink: 0;
      color: inherit;
      font-size: 2.5rem;
    }
  }

  .only-text {
    font-size: 2rem;
    line-height: 2.2rem;
    color: $neutral-g-100;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 0.8rem;

    .help {
      position: relative;
      top: unset;
      right: unset;
      flex-shrink: 0;
      color: inherit;
      font-size: 2.5rem;
      margin-top: 10px;
    }
  }
}
