
          @use "@assets/scss/_config.scss" as *;
        
.container {
  background: $primary-100;
  border: 1px solid $primary-200;
  color: $primary-600;
  font-weight: 600;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: 5.6rem;
  height: 3.2rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  padding: 0 0.8rem;

  &[data-tag-size='l'] {
    border-radius: 8px;
    height: 4rem;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: 6.8rem;
    gap: 1.2rem;
  }

  &[data-tag-disabled='true'] {
    color: $neutral-600;
    background: $neutral-100;
    border-color: $neutral-200;
    cursor: not-allowed;

    .remove {
      cursor: not-allowed;
    }
  }

  &:hover:not([data-tag-disabled='true']) {
    color: $primary-500;
  }

  .remove {
    cursor: pointer;
    font-size: 1.1rem;
    color: $neutral-800;
    @extend %inline-flex-center;
  }
}
