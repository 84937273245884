
          @use "@assets/scss/_config.scss" as *;
        
.modal {
  overflow-x: hidden;
  margin: 3rem;
  width: 84rem;
  min-height: 50rem; 
  .loader {
    margin-top:25rem
  }
}
