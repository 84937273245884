
          @use "@assets/scss/_config.scss" as *;
        
/* stylelint-disable selector-class-pattern */

.react-datepicker-wrapper {
  height: 100%;
  position: absolute;
}

.react-datepicker__input-container {
  height: 100%;
  z-index: unset;
}

.react-datepicker-popper {
  left: 0 !important;
  width: 100% !important;
  z-index: 10;
}

.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 0;
}

.react-datepicker-popper[data-placement^='top'] {
  padding-bottom: 0;
}

.custom-datepicker-clear-button {
  right: 3rem;
  padding: 0;

  &::after {
    height: 1.6rem;
    width: 1.6rem;
    font-size: 1.2rem;
    background: $neutral-800;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    &::after {
      background: $danger-600;
    }
  }
}

.custom-datepicker-calendar {
  width: var(--date-picker-width);
  min-width: fit-content;
  height: auto;
  background: $neutral-0;
  border: 1px solid $neutral-200;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 2rem 1.6rem 1.6rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-family: $primary-font;
  color: $neutral-800;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);

  @extend %inline-flex-center;

  .react-datepicker__input-time-container {
    display: flex;
    align-items: center;
    font-weight: 600;
    text-transform: capitalize;
    gap: 2rem;
    padding: 0 1.6rem 1rem 0;
    grid-area: 1/1/2/3;
    @extend %small-text;

    .react-datepicker-time__caption {
      flex-shrink: 0;
    }

    .react-datepicker-time__input-container {
      width: 100%;

      .react-datepicker-time__input {
        margin-left: 0;
        width: 100%;

        input {
          border-radius: 8px;
          padding: 0 1rem;
          height: 3rem;
          text-transform: capitalize;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          background: $neutral-0;
          border: 1px solid $neutral-200;
          outline: 0;
          font-family: $primary-font;

          &:focus {
            border: 1px solid $secondary-g-700;
          }

          &::placeholder {
            color: $neutral-500;
          }
        }
      }
    }
  }

  .react-datepicker__time-container {
    grid-area: 2/2/3/3;
    border-left: 1px solid $neutral-150;

    .react-datepicker__header--time {
      background: $neutral-100;
      border-bottom: 1px solid $neutral-150;

      .react-datepicker-time__header {
        font-weight: 600;
        @extend %small-text;
      }
    }

    .react-datepicker__time {
      .react-datepicker__time-box {
        .react-datepicker__time-list {
          .custom-datepicker-time {
            display: flex;
            align-items: center;

            &:hover:not(.react-datepicker__time-list-item--selected) {
              background: $neutral-100;
            }

            &.react-datepicker__time-list-item--selected {
              background: $secondary-g-700;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .react-datepicker__time-list::-webkit-scrollbar {
    width: 6px;
  }

  .react-datepicker__time-list::-webkit-scrollbar-track {
    background: $neutral-150;
    border-radius: 8px;
    position: relative;
  }

  .react-datepicker__time-list::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: $neutral-0;
    border: 1px solid $neutral-150;
    background-clip: padding-box;
  }

  .react-datepicker__month-container {
    grid-area: 2/1/3/2;
    width: 100%;
    height: 100%;

    .react-datepicker__header {
      padding: 0;
      border-bottom: 0;
      background-color: $neutral-0;
      border-top-left-radius: 0;
      margin-bottom: 1.2rem;

      .react-datepicker__day-names {
        margin-top: 1.5rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.4rem;

        .react-datepicker__day-name {
          width: 3.2rem;
          height: 2.4rem;
          @extend %inline-flex-center !optional;
        }
      }
    }

    .react-datepicker__month {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      margin: 0;

      .react-datepicker__week {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.4rem;

        .react-datepicker__day {
          width: 3.2rem;
          height: 2rem;
          color: $neutral-800;
          transition: all 0.3s linear;
          @extend %inline-flex-center !optional;

          &.react-datepicker__day--outside-month {
            color: $neutral-500;
          }

          &.react-datepicker__day--disabled {
            cursor: not-allowed;
          }

          &.react-datepicker__day--keyboard-selected {
            color: $neutral-800;
            background: $neutral-0;
          }

          &.react-datepicker__day--today {
            color: $secondary-g-700;
            font-weight: 600;
            background: $neutral-300;
            border-radius: 4px;
            transition: all 0.3s linear;
          }

          &.react-datepicker__day--selected {
            color: $secondary-g-700;
            font-weight: 600;
            background: $primary-100;
            border-radius: 4px;
            transition: all 0.3s linear;
          }

          &:hover:not(.react-datepicker__day--disabled),
          &.react-datepicker__day--in-range,
          &.react-datepicker__day--in-selecting-range {
            color: $secondary-g-700;
            font-weight: 600;
            background: $neutral-100;
            border-radius: 4px;
            transition: all 0.3s linear;
          }
        }
      }
    }
  }
}

.react-datepicker__tab-loop {
  .react-datepicker-popper[data-placement^='top'] {
    padding-bottom: 0;
  }
}
