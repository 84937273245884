
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  overflow-y: scroll;
}
