
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: inline-flex;
  align-items: center;
  z-index: 1;
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;

  &.container-data {
    &.checked {
      .row-gradient {
        background: linear-gradient(90deg, $neutral-100 50.52%, rgb(255 255 255 / 0%) 100%);
        border-bottom: 0;
      }

      .checkbox {
        background: $neutral-100;
        border-bottom: 0;

        &::before {
          background: transparent;
        }
      }
    }
  }

  .checkbox {
    padding: 0 2rem 0 2.4rem;
    background: $neutral-0;
    height: 100%;
    display: flex;
    position: relative;
    border-bottom: 1px solid $neutral-200;

    &::before {
      content: '';
      background: $neutral-0;
      position: absolute;
      bottom: -1px;
      height: 1px;
      width: 1.2rem;
      left: 0;
    }
  }

  .row-gradient {
    height: 100%;
    position: relative;
    width: 2rem;
    background: linear-gradient(90deg, $neutral-0 50.52%, rgb(255 255 255 / 0%) 100%);
    border-bottom: 1px solid $neutral-200;
  }
}
