
          @use "@assets/scss/_config.scss" as *;
        
.container {
  background: $neutral-100;
  border: 1px solid $neutral-150;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 6.4rem;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    gap: 2.2rem;
    height: 100%;

    .back {
      font-size: 2.5rem;
      cursor: pointer;
      @extend %inline-flex-center;
    }

    .navigation {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      height: 100%;

      li {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        transition: all 0.3s ease-in;
        gap: 0.6rem;


        button {
          height: 100%;
          text-transform: capitalize;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: black;

          &:focus-visible,
          &:focus {
            outline: unset;
          }
        }

        .help {
          flex-shrink: 0;
          color: inherit;
          position: relative;
          top: unset;
          right: unset;
          font-size: 2rem;
        }

        &.active {
          opacity: 1;
          transition: all 0.3s ease-in;

          &::before {
            position: absolute;
            content: '';
            background: $primary-600;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
          }

          button {
            color: $primary-600;
          }

          .help {
            color: $primary-600;
          }
        }
      }
    }

    h3,
    .navigation button {
      font-weight: 600;
      color: $neutral-600;
      font-size: 1.4rem;
      line-height: 1.7rem;
      text-transform: capitalize;
    }

    h3 {
      opacity: 1;
      color: $neutral-800;
      gap: 0.6rem;
      display: flex;
      align-items: center;

      .help {
        flex-shrink: 0;
        color: inherit;
        position: relative;
        top: unset;
        right: unset;
        font-size: 2rem;
      }

      &.active {
        color: $primary-600;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;

        &::before {
          position: absolute;
          content: '';
          background: $primary-600;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
        }
      }
    }
  }
}
