
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-items: center;
  gap: 0.1rem;
  color: $neutral-800;
  font-size: 1.7rem;

  span {
    @extend %inline-flex-center;
  }

  .arrow {
    &.arrow-open {
      transform: rotate(-180deg);
    }
  }

  .clear {
    @extend %inline-flex-center;

    &:hover {
      color: $danger-600;
    }
  }
}
