%inline-flex-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
