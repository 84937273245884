
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-items: center;
  z-index: 1;
  position: sticky;
  right: 0;
  height: 100%;

  &.container-data {
    &.checked {
      .button-wrapper {
        background: $neutral-100;
        border-bottom: 0;

        &::before {
          background: transparent;
        }
      }

      .row-gradient {
        background: linear-gradient(90deg, $neutral-100 50.52%, rgb(255 255 255 / 0%) 100%);
        border-bottom: 0;
      }
    }
  }

  .row-gradient {
    height: 100%;
    position: relative;
    width: 2rem;
    background: linear-gradient(90deg, $neutral-0 50.52%, rgb(255 255 255 / 0%) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    border-bottom: 1px solid $neutral-200;
  }

  .button-wrapper {
    // padding: 0 2rem;
    background: $neutral-0;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $neutral-200;
    position: relative;

    .export {
      margin-right: 1rem;
    }

    &::before {
      content: '';
      background: $neutral-0;
      position: absolute;
      bottom: -1px;
      height: 1px;
      width: 1.2rem;
      right: 0;
    }

    & > button {
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  .export-button-wrapper {
    padding-right: 2rem;
  }
  .nonexport-button-wrapper {
    padding: 0 3rem;
  }

  .default-button-wrapper{
    padding: 0 2rem;
  }
  
}
