
          @use "@assets/scss/_config.scss" as *;
        
.setting-root-container {
  overflow-y: scroll;
  width: 98%;
  position: relative;
  min-height: calc(100vh - 100px); // Adjust based on your header/footer height

  .accordion-style {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 2rem;
    width: 100%;
  }

  .accordion-style > * {
    margin-bottom: 2rem;
  }
}

.license-link {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  margin-top: 'auto';
  margin-bottom: 1rem;

  a {
    color: #666;
    font-size: 0.875rem;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      color: #2a28f3;
      text-decoration: underline;
    }
  }
}
/* For small screens (max-width: 768px) */
@media (max-width: 768px) {
  .license-link {
    margin-bottom: 3rem;
  }
}

/* For extra small screens (max-width: 576px) */
@media (max-width: 576px) {
  .license-link {
    margin-bottom: 3.5rem;
  }
}
.setting-root-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.header-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
