
          @use "@assets/scss/_config.scss" as *;
        
.container {
  height: 3.2rem;
  width: 8.6rem;
  background: $neutral-0;
  border: 1px solid $neutral-200;
  border-radius: 8px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  position: relative;
  cursor: pointer;
  @extend %inline-flex-center;

  .legend {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;

    .icon {
      font-size: 1.6rem;
      transition: transform 0.3s ease;
      @extend %inline-flex-center;
    }
  }

  .details {
    border-radius: 8px;
    position: absolute;
    top: calc(100% + 0.4rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.9rem;
    right: 0;
    background: $neutral-0;
    padding: 0.4rem 1.2rem;
    width: 14.2rem;
    height: 7.2rem;

    p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      font-size: 1.2rem;
      line-height: 1.5rem;

      .highlight {
        width: 2.4rem;
      }

      &.suggested,
      &.alternative {
        .highlight {
          width: 2.4rem;
          height: 3px;
        }
      }

      &.suggested {
        .highlight {
          background: $secondary-500;
        }
      }

      &.alternative {
        .highlight {
          background: $tertiary-500;
        }
      }

      &.tolls {
        .highlight {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &::before {
            content: '';
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            background: $tertiary-700;
            border: 2px solid $neutral-0;
          }

          &::after {
            content: '';
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            background: $secondary-700;
            border: 2px solid $neutral-0;
          }
        }
      }
    }
  }

  &.open {
    .legend {
      .icon {
        transform: rotate(-180deg);
        transition: transform 0.3s ease;
      }
    }
  }
}
