
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  height: 100%;
  position: absolute;

  .map {
    width: 100%;
    height: 100%;
  }

  .tollDetails {
    display: flex;
    flex-direction: column;
  }

  .Details {
    display: flex;
    flex-direction: row;
  }

  .heading {
    color: #004dcf;
  }

  .padding {
    margin-left: 5px;
  }
}
