
          @use "@assets/scss/_config.scss" as *;
        
.container {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 600;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    padding: 4rem 6rem;
    gap: 2.5rem;

    .row {
      width: 100%;
      display: flex;
      gap: 3rem;
    }

    .consent {
      gap: 1rem;
      align-items: center;

      &.error {
        color: $danger-600;

        .checkbox {
          span:first-child {
            border-color: $danger-600;
          }
        }
      }
    }

    .input {
      width: calc(50% - 1rem);
      display: flex;
      input {
        border-radius: 0;
        background: $neutral-g-0;
      }
      .checkbox-name {
        .checkbox-field {
          margin-right: 0.3rem;
        }
      }
    }

    .file {
      width: 100%;
    }

    .submit {
      display: none;
    }
  }
}
