
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 2rem 3rem;
  height: 53.2rem;
  gap: 3rem 2rem;

  .input {
    width: 35rem;
  }
}
