
          @use "@assets/scss/_config.scss" as *;
        
.select-customer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25rem;
}

.select-customer-button {
  height: 1rem;
}
