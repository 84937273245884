
          @use "@assets/scss/_config.scss" as *;
        
.container {
        .retry {
            padding-top: 1rem;

            .icon {
                width: 1.1rem;
            }
        }
    

    .loader {
        text-align: center;
        padding-top: 8rem;
    }
}

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 25rem;
    height: 100%;
}