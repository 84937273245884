
          @use "@assets/scss/_config.scss" as *;
        
/* stylelint-disable property-no-unknown */
/* stylelint-disable selector-pseudo-class-no-unknown */
@use 'sass:color';

.container {
  position: fixed;
  inset: 0;
  height: 100vh;
  background: color.adjust($neutral-900, $alpha: -0.6);
  z-index: $modal-z-index;
  @extend %flex-center;
}

:export {
  backgroundHidden: color.adjust($neutral-900, $alpha: -1);
  backgroundVisible: color.adjust($neutral-900, $alpha: -0.6);
}
