
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 3.2rem;
  width: auto;

  .arrow {
    color: $neutral-g-500;
    font-size: 2rem;
    border-radius: 4px;
    height: 100%;
    width: 3.2rem;
    cursor: pointer;
    @extend %inline-flex-center;

    &:hover {
      background: $neutral-100;
      color: $neutral-g-500;
    }

    &.disabled {
      color: $neutral-g-300;
      cursor: not-allowed;
    }

    &.left {
      transform: rotate(90deg);
    }

    &.right {
      transform: rotate(-90deg);
    }
  }

  .first,
  .last {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $neutral-g-500;
    height: 100%;

    &:hover {
      background: $neutral-100;
      color: $neutral-g-500;
    }

    &:disabled {
      color: $neutral-g-300;
      cursor: not-allowed;
    }

    .arrow {
      width: fit-content;

      &.left {
        transform: rotate(180deg);
      }

      &.right {
        transform: rotate(0deg);
      }
    }

    .text {
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: 600;
    }
  }

  .box {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
    height: 100%;

    .buttons {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      height: 100%;

      li {
        height: 100%;

        button {
          border-radius: 4px;
          height: 100%;
          min-width: 3.2rem;
          padding: 0 0.5rem;
          font-size: inherit;
          line-height: inherit;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          transition: 0.2s linear;

          &.active {
            color: $neutral-g-100;
            font-weight: 600;
            background: $secondary-g-700;
          }

          &:hover:not(.active) {
            transition: 0.2s linear;
            font-weight: 600;
            color: $neutral-g-100;
            background: $secondary-500;
          }
        }
      }

      .ellipses {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 3.2rem;
        display: flex;
        justify-content: center;
      }
    }
  }

  .data-length {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
