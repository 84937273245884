
          @use "@assets/scss/_config.scss" as *;
        
.modal {
  overflow-x: hidden;
  width: 103rem;
}

.success {
  overflow-x: hidden;
  width: 40rem;
}

.loader {
  margin: 4rem 45%;
  width: 3rem;
  height: 3rem;
  border-width: 0.5rem;
  place-self: center;
  display: inline-block;
}

.text {
  margin: 2rem;
  font-size: small;
  font-weight: 500;
  text-align: center;
}
