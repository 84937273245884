
          @use "@assets/scss/_config.scss" as *;
        
.container {
  background: $neutral-100;
  height: 100vh;
  z-index: $navigation-bar-z-index;
  display: flex;
  padding-right: 0.6rem;

  .menu {
    background: $neutral-0;
    width: 100%;
    height: 100%;
    border-right: 1px solid $neutral-150;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .top,
    .bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-right: 0;
    }

    .bottom {
      justify-content: flex-end;
    }
  }
}
