
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  display: grid;
  background: #fff;
  border: 1px solid #eaeaef;
  border-radius: 8px;
  margin-top: 1rem;
}

.tableWrapper {
  padding: 2rem;
}

.title {
  display: flex;
  justify-content: space-between;
}

.header {
  width: 100%;
}

.header h3 {
  @extend %table-label;
  font-weight: bold;
  text-align: left;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  // color: #3c5063;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 450px);
  cursor: pointer;
}

.row {
  width: 100%;
  height: 3.3rem;
  display: flex;
  align-items: center;
  padding: 3rem 0;
  position: relative;
}

.row::before {
  position: absolute;
  content: '';
  background: $neutral-200;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}

.text {
  display: grid;
  grid-template-columns: minmax(10rem, 28rem) minmax(35rem, 28rem) minmax(16rem, 14rem);
  align-items: center;
  margin-left: 6%;
}

.text h3,
.text p {
  text-align: left;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  overflow-wrap: break-word;
}
p {
  font-size: small;
}

.text input[type='checkbox'] {
  margin-left: auto;
  margin-right: 1rem;
}

#name {
  grid-column: 1 / 2;
}

#email {
  grid-column: 2 / 3;
}

#role {
  grid-column: 3 / 4;
}

.loader {
  margin: 17.4rem 48%;
  width: 3rem;
  height: 3rem;
  border-width: 0.5rem;
  place-self: center;
  display: inline-block;
}

.noData {
  padding: 17.4rem 1rem;
  display: flex;
  justify-self: center;
}

.search-bar {
  width: 28%;
}
