
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  @extend %flex-center;

  h3 {
    @extend %header1;
  }

  p,
  a {
    color: $neutral-600;
    margin: 1rem 0 2.5rem;
    width: 30rem;
    text-align: center;
    @extend %subtitle;
  }
}
