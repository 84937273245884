
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.1rem;
  line-height: 1.3rem;
  font-weight: 700;
  color: $neutral-500;

  .action {
    all: inherit;
    margin-left: 0.4rem;
    cursor: pointer;

    .sorting {
      color: $neutral-800;
      margin-right: 0.4rem;
    }

    .arrows {
      color: $neutral-800;
      font-size: 1.3rem;
    }
  }
}
