
          @use "@assets/scss/_config.scss" as *;
        
.modal {
  max-width: 40rem !important;
  .modal-content {
    min-height: 18rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem;
    .header {
      font-size: 2rem;
      padding: 1rem;
      text-align: center;
      font-weight: bold;
    }
    .body {
      font-size: 1.4rem;
      text-align: center;
    }
    .login-btn {
      margin-top: 1.5rem;
      font-size: 1.6rem;
      text-align: center;
    }
  }
}
