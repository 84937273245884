
          @use "@assets/scss/_config.scss" as *;
        
.upload {
  width: fit-content;
  display: block;
}

.container {
  width: 26rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  label {
    font-weight: 600;
    text-transform: capitalize;
    padding-left: 1.2rem;
    z-index: 0;
    @extend %small-text;
  }

  &[data-input-disabled='true'] {
    .input {
      background: $neutral-100;
      cursor: not-allowed;

      &:hover {
        border: 1px solid $neutral-200;
        background: $neutral-100;
      }
    }
  }

  &.dragging {
    cursor: grabbing;

    .input {
      border: 1px solid $primary-600;
    }
  }

  &.container-error {
    .input {
      border: 1px solid $danger-600;

      &:hover {
        border: 1px solid $danger-600;
      }
    }

    .error-description {
      padding-left: 1.2rem;
      margin-top: 0.4rem;
      color: $danger-600;
      @extend %small-text;
    }
  }
}

.input,
.disabled-input {
  margin-top: 0.6rem;
  padding: 1.2rem;
  padding-right: 3.2rem;
  background: $neutral-0;
  border: 1px solid $neutral-200;
  border-radius: 8px;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  cursor: pointer;
  z-index: 0;
  position: relative;
  transition: all 0.3s ease;
  font-size: 1.4rem;
  line-height: 1.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .filename {
    color: $neutral-800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .placeholder {
    color: $neutral-500;
    width: 100%;
    display: flex;
    align-items: center;

    .browse {
      color: $primary-600;
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      width: fit-content;
      display: inline;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-left: 0.7ch;
    }

    .file {
      font-size: 2rem;
      color: $primary-600;
      margin-right: 1rem;
      @extend %inline-flex-center;
    }
  }

  &:hover:not(.disabled-input) {
    transition: all 0.3s ease;
    border: 1px solid $primary-200;
    background: $neutral-0;
  }

  &.input-active {
    border: 1px solid $primary-600;
  }

  .loader {
    position: absolute;
    right: 1.2rem;
    width: fit-content;
  }

  .clear {
    position: absolute;
    right: 1.2rem;
    font-size: 2rem;
    width: fit-content;
    @extend %inline-flex-center;
  }
}

.disabled-input {
  background: $neutral-100;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    border: 1px solid $neutral-200;
  }
}
