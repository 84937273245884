
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 2rem 3rem;
  height: 53.2rem;
  gap: 3rem 2rem;

  .input {
    width: 35rem;
  }

  .geotab-toggle {
    width: 35rem;

    .label {
      padding: 0 1.2rem;
      text-align: left;
      font-weight: 600;
      @extend %small-text;
    }

    .toggle {
      margin-top: 0.6rem;
    }
  }
}
