
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 23rem;

  label,
  input,
  .disabled-input,
  .error-description {
    padding: 0 1.2rem;
    text-align: left;
  }

  label {
    font-weight: 600;
    @extend %small-text;
  }

  .input-container {
    margin-top: 0.6rem;
    height: 4rem;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    --icon-box-width: 4rem;

    &:hover:not([data-text-input-disabled='true']):not(:focus-within) {
      input,
      .disabled-input,
      .icon-container {
        transition: all 0.3s ease;
        border-color: $primary-200;
      }
    }

    &[data-text-input-disabled='true'] {
      .icon-container {
        background: $neutral-100;
        cursor: not-allowed;
        border-color: 1px solid $neutral-200;
      }
    }

    &:focus-within {
      .icon-container {
        border-color: $primary-600;
      }
    }

    .icon-container {
      width: var(--icon-box-width);
      height: 100%;
      background: $neutral-100;
      border: 1px solid $neutral-200;
      border-right: none;
      border-radius: 8px 0 0 8px;
      color: $primary-600;
      font-size: 1.6rem;
      transition: all 0.3s ease;
      @extend %inline-flex-center;

      & ~ input,
      & ~ .disabled-input {
        border-radius: 0 8px 8px 0;
        padding: 0 1rem;
        width: calc(100% - var(--icon-box-width));
      }
    }

    input,
    .disabled-input {
      transition: all 0.3s ease;
      background: $neutral-0;
      padding: 0 1.2rem;
      border-radius: 8px;
      border: 1px solid $neutral-200;
      width: 100%;
      height: 100%;
      font-family: $primary-font;
      font-size: 1.4rem;
      line-height: 1.7rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::placeholder {
        color: $neutral-500;
      }

      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: unset;
        border: 1px solid $primary-600;
      }

      &:disabled {
        background: $neutral-100;
        cursor: not-allowed;

        &:hover {
          border: 1px solid $neutral-200;
        }

        &::placeholder {
          color: $neutral-800;
        }
      }
    }

    .password-icon {
      width: fit-content;
      position: absolute;
      right: 1rem;
      font-size: 2.5rem;

      @extend %inline-flex-center;
    }

    .disabled-input {
      background: $neutral-100;
      cursor: not-allowed;
      display: flex;
      align-items: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        border: 1px solid $neutral-200;
      }
    }
  }

  &.container-error {
    .input-container {
      input,
      .disabled-input,
      .icon-container {
        border-color: $danger-600;

        &:focus {
          border-color: $danger-600;
        }
      }

      &:hover:not([data-text-input-disabled='true']):not(:focus-within) {
        input,
        .disabled-input,
        .icon-container {
          border-color: $danger-600;
        }
      }

      .icon-container {
        color: $danger-600;
      }
    }

    .error-description {
      margin-top: 0.4rem;
      color: $danger-600;
      @extend %small-text;
    }
  }
}
