
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-items: center;
  height: 3.2rem;
  width: 11.4rem;
  gap: 0.5rem;

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    height: 100%;

    button {
      font-size: 1.2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s linear;
      height: 100%;

      &:hover {
        transition: 0.2s linear;
      }
    }

    .search {
      background: $neutral-g-100;
      color: $primary-g-700;

      &:hover {
        background: $primary-600;
        color: #fff;
      }
    }

    .clear {
      background: $neutral-g-100;
      color: $primary-g-700;

      &:hover {
        background: $danger-600;
        color: #fff;
      }
    }
  }

  .box {
    width: 3.2rem;
    height: 100%;
    background: $primary-g-700;
    border: 1px solid $neutral-g-100;
    flex-grow: 1;
    position: relative;

    & > div {
      width: 100%;
      height: 100%;

      & > div {
        width: 100%;
        height: 100%;
      }
    }

    input {
      font-family: $primary-font;
      width: 100%;
      height: 100%;
      background: transparent;
      border: 0;
      color: #fff;
      text-align: center;

      &::placeholder {
        color: #f6f6f6;
      }

      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: unset;
      }
    }

    .dropdown {
      color: $primary-g-700;
      height: 14rem;
      width: 8rem;

      & > ul {
        height: 100%;
        width: 100%;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
