
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 26rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  label {
    font-weight: 600;
    text-transform: capitalize;
    padding-left: 1.2rem;
    z-index: 0;
    @extend %small-text;
  }

  &[data-input-disabled='true'] {
    .input {
      background: $neutral-100;
      cursor: not-allowed;

      &:hover {
        border: 1px solid $neutral-200;
        background: $neutral-100;
      }

      .add {
        color: $neutral-600;
        background: $neutral-100;
        border-color: $neutral-200;
        cursor: not-allowed;
      }

      .tags {
        .new-tag {
          input {
            cursor: not-allowed;
            color: $neutral-600;
            background: $neutral-100;
            border-color: $neutral-200;
          }

          .clear-input {
            cursor: not-allowed;
          }
        }
      }

      .clear {
        cursor: not-allowed;
      }
    }
  }

  &.container-focused {
    .input {
      border: 1px solid $primary-600;
    }
  }

  &.container-error {
    .input {
      border: 1px solid $danger-600;

      &:hover {
        border: 1px solid $danger-600;
      }
    }

    .error-description {
      padding-left: 1.2rem;
      margin-top: 0.4rem;
      color: $danger-600;
      @extend %small-text;
    }
  }
}

.input {
  margin-top: 0.6rem;
  padding: 0.4rem;
  padding-right: 3.2rem;
  background: $neutral-0;
  border: 1px solid $neutral-200;
  border-radius: 8px;
  min-height: 4rem;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  cursor: pointer;
  z-index: 0;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    transition: all 0.3s ease;
    border: 1px solid $primary-200;
    background: $neutral-0;
  }

  &.input-active {
    border: 1px solid $primary-600;
  }

  .tags {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 0.4rem 0.7rem;
    width: 100%;

    .new-tag {
      flex-grow: 0;
      height: 3.2rem;
      width: fit-content;
      display: inline-flex;
      align-items: center;
      position: relative;

      input {
        height: 100%;
        width: 4rem;
        min-width: fit-content;
        background: $primary-100;
        color: $primary-600;
        border: 1px solid $primary-200;
        border-radius: 4px;
        padding: 8px 6px 8px 8px;
        line-height: 1.5rem;
        font: {
          family: $primary-font;
          weight: 600;
          size: 1.2rem;
        }

        &:focus,
        &:focus-within,
        &:focus-visible {
          outline: 0;
          border: 1px solid $primary-200;
        }
      }

      .clear-input {
        font-size: 1.1rem;
        position: absolute;
        right: 1.2rem;
        @extend %inline-flex-center;
      }
    }

    .add {
      font-size: 1.6rem;
      width: 3.2rem;
      height: 3.2rem;
      color: $primary-600;
      background: $primary-100;
      border: 1px solid $primary-200;
      border-radius: 8px;
      @extend %inline-flex-center;
    }
  }

  .clear {
    position: absolute;
    right: 1rem;
    top: 1.2rem;
    font-size: 1.5rem;
    @extend %inline-flex-center;
  }
}
