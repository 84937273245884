
          @use "@assets/scss/_config.scss" as *;
        
.modal {
  width: 40rem;
}

.container {
  height: 42.4rem;
  border: 1px solid $neutral-150;
  border-radius: 8px;
  background: #fff;
  padding: 2rem;
  flex-direction: column;
  @extend %flex-center;

  h3 {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-top: 5rem;
    text-align: center;
  }

  p {
    max-height: 15rem;
    overflow: auto;
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-top: 1.5rem;
    text-align: center;
    width: (50ch, 80%);
  }

  button {
    margin-top: 2rem;
    flex-shrink: 0;
  }

  .icon {
    font-size: 15rem;
  }

  &.success {
    .icon {
      color: $success-600;
    }
  }

  &.error {
    .icon {
      color: $danger-600;
    }
  }
}
