
          @use "@assets/scss/_config.scss" as *;
        
.container {
    .carousel-container {
        width: 100%;
        margin-top: 3rem;
    }

    .card-wrapper {
        cursor: pointer;
    }

    .card {
        height: 22.5rem;
        border: 1px solid lightgray;
        width: 100%;
    }

    .content {
        margin-top: 1rem;
    }

    .centeredTypography {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: blue;
        text-decoration: underline;
        width: 100%;
        margin-top: 5rem;

        .custom-font-size {
            font-size: large;
        }
    }

    .loader {
        display: flex;
        gap: 3rem;
        margin-top: 3rem;
        justify-content: center;
    }

    .details {
        height: 5rem;
    }
}
.viewall {
    display: inline-block;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    margin-top: 5rem;
    font-size: 1.5rem !important;
}