
          @use "@assets/scss/_config.scss" as *;
        
.container {
    position: relative;
    width: 100%;
    background: $neutral-g-0;
    z-index: $view-header-z-index;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.8rem 0;

    .title {
        display: flex;
        flex-direction: column;
       
    }

    .customer {
        color: #000000;
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 0.6rem;
        line-height: 1.9rem;
        // text-transform: capitalize;
    }

}