
          @use "@assets/scss/_config.scss" as *;
        
.container {
  flex: 1;
  overflow: auto;

  .scrollableContent {
    margin-top: 3rem;
    margin-left: 6rem;
    width: 96%;

    .welcome-text {
      font-size: medium;
      font-weight: 500;
      line-height: 3rem;
    }

    span {
      display: flex;
      margin-left: 1.25rem;
      margin-top: 1.3rem;
      align-items: center;
    }

    h1 {
      font-size: medium;
    }

    p {
      font-size: medium;
      margin-left: 1rem;
      font-weight: 500;
    }

    .notice_div {
      margin-top: 2rem !important;
    }
    .icon {
      stroke-width: 3.1;
    }

    > div {
      margin-top: 3rem;
    }
  }
}
