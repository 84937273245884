
          @use "@assets/scss/_config.scss" as *;
        
.container {
  border: 1px solid $neutral-g-300;
  width: min(1200px, 95%);
  position: relative;

  .header {
    height: 8rem;
    background: $tertiary-g-600;
    padding: 0 3rem;
    border-bottom: 1px solid $neutral-g-300;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $neutral-g-100;
    font-size: 2rem;
    line-height: 2.2rem;
  }

  .body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 300px;
    padding: 0 20px;
  }

  .geotab-logo {
    max-width: 230px;
  }

  .cl-logo {
    min-width: 100px;
  }

  .sso-loader {
    width: 20em;
    height: 0.66em;
    border: 0.05em currentcolor solid;
    border-radius: 0.1em;
    background: linear-gradient(
      -60deg,
      transparent 0%,
      transparent 50%,
      currentcolor 50%,
      currentcolor 75%,
      transparent 75%,
      transparent
    );
    background-size: 1em 2em;
    background-position: 0 0;
    -webkit-animation: sso-loader 0.8s infinite linear;
    animation: sso-loader 0.8s infinite linear;
  }
}

@keyframes sso-loader {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -2em 0;
  }
}

@keyframes sso-loader {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -2em 0;
  }
}
