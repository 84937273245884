
          @use "@assets/scss/_config.scss" as *;
        
.container {
  position: absolute;
  top: calc(100% + 0.45rem);
  background: $neutral-0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 12.7rem;
  min-width: fit-content;
  min-height: 4.2rem;
  height: fit-content;
  gap: 0.45rem;
  padding: 0.4rem 0.8rem;

  p {
    display: flex;
    align-items: center;
    gap: 0.45rem;
    font-size: 1.2rem;
    line-height: 1.5rem;

    .icon {
      @extend %inline-flex-center;
    }

    &.money {
      .icon {
        font-size: 1.4rem;
      }
    }
  }

  &.actual {
    border: 1px solid $primary-600;
    left: 0;

    .icon {
      color: $primary-600;
    }
  }

  &.alt {
    border: 1px solid $tertiary-700;
    right: 0;

    .icon {
      color: $tertiary-700;
    }
  }

  .no-alternative {
    color: red;
  }

  .npvt-positive-text {
    color: green;
  }
  .npvt-negative-text {
    color: red;
  }
}
