
          @use "@assets/scss/_config.scss" as *;
        
.container {
  background: $neutral-0;
  border: 1px solid $neutral-200;
  border-radius: 8px;
  padding: 4rem;
  width: 48rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .logo {
    margin-bottom: 3rem;

    picture:first-child {
      width: 6rem;
      height: 6rem;
    }
  }
}
