
          @use "@assets/scss/_config.scss" as *;
        
.container {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  .display {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;

    .selected {
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-weight: 600;
    }

    .arrow {
      color: $neutral-500;
      font-size: 0.8rem;
      @extend %inline-flex-center;
    }
  }
}

.dropdown {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 20rem;
  padding-bottom: 0;

  ul {
    height: 100%;
  }

  svg {
    display: none;
  }
}

.dropdown::-webkit-scrollbar {
  width: 0;
}
