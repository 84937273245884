
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  height: 100%;
  position: relative;

  .map {
    width: 100%;
    height: 100%;
  }
}
