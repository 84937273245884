
          @use "@assets/scss/_config.scss" as *;
        
.gridContainer {
  height: 25rem;
}

.total {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.totalItem {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.invoiced,
.tollogic,
.squareBox {
  width: 15px;
  height: 15px;
  margin-right: 0.5rem;
  border-radius: 2px;
}

.invoiced {
  background-color: #004c6d;
}

.tollogic {
  background-color: #a3c8e7;
}

.value {
  margin-left: 1.5rem;
  color: #000000;
  font-size: 1.5rem;
  padding-left: 16px;
}
