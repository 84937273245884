
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: min(88rem, 90%);
  height: 48rem;
  background: $neutral-0;
  border: 1px solid $neutral-300;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .logo {
      picture {
        width: 6rem;
        height: 6rem;
      }
    }

    .title {
      margin: 3rem 0 4rem;
      color: $warning-700;
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.6rem;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
      width: 90%;

      h3 {
        font-weight: 600;
      }
    }
  }

  footer {
    padding: 1.6rem 4rem;
    border-top: 1px solid $neutral-g-300;
    width: 100%;
    height: fit-content;
    margin-top: auto;
    display: flex;
    justify-content: flex-end;

    button {
      height: 3.2rem;
      min-width: 4.9rem;
      width: fit-content;
      border-radius: 4px;
      font-size: 1.2rem;
      line-height: 1.4rem;

      &.reload {
        background: #0078d3;
        color: #fff;
        margin-left: auto;
        transition: 0.2s linear;

        &:hover {
          opacity: 0.8;
          transition: 0.2s linear;
        }
      }
    }
  }
}
