
          @use "@assets/scss/_config.scss" as *;
        
.tableWrapper {
  padding: 1rem;

  table {
    border-collapse: collapse;
    min-width: 102rem;
  }

  th {
    padding: 2rem !important;
    cursor: pointer;
    text-transform: capitalize;
  }

  td {
    text-align: center;
    margin-top: 5rem;
    padding: 0.5rem;
  }

  tr {
    border-bottom: 1px solid lightgray;
    padding: 1rem 0.5rem;
  }
}

.hide-table {
  display: none;
}

.container {
  width: 100%;
  display: grid;
  background: #fff;
  border-top: 1px solid #eaeaef;
  border-radius: 8px;
  overflow-x: auto;
}

.header {
  width: 100%;
}

.header h3 {
  @extend %table-label;
  font-weight: bold;
  text-align: center;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 450px);
}

.row {
  width: 100%;
  height: 3.3rem;
  display: flex;
  align-items: center;
  padding: 3rem 0;
  position: relative;
}

.row::before {
  position: absolute;
  content: '';
  background: $neutral-200;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}

.text {
  display: flex;
  align-items: center;
  width: 100%;

  .payment_status {
    display: block !important;
    margin-left: 3rem;
  }
  .paymentStatus {
    position: relative;
    right: 1.5rem;
  }
}

.text h3,
.text p {
  text-align: left;
  margin-bottom: 0.5rem;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invoice-download {
  padding: 1rem;
  position: absolute;
  z-index: 500;
  right: 0;
  top: -2.5rem;
}

.invoice-grid {
  display: flex;
  margin-top: 0.7rem !important;
  justify-content: end;
  margin-right: 20%;
  .flex-wrapper {
    display: grid;
    gap: 1rem;
    justify-content: end;
    .invoice-amount-wrapper {
      grid-column: 1;
    }
    .chip-wrapper {
      grid-column: 2;
      min-width: 8.2rem;
    }
  }
}

.action-handler {
  display: flex;
  min-width: 3rem;
  justify-content: end;
  .pay-btn {
    // margin-top: 0.8rem;
    // height: 2rem !important;
  }
  .normal-dwnld {
    margin-left: 1rem;
  }
}

.text input[type='checkbox'] {
  margin-left: auto;
  margin-right: 1rem;
}

.loader {
  margin: 17.4rem 48%;
  width: 3rem;
  height: 3rem;
  border-width: 0.5rem;
  place-self: center;
  display: inline-block;
}

.noData {
  padding: 4rem 0rem;
}

.icon {
  svg {
    path {
      stroke-width: 0.5px;
    }
  }

  &.ASC {
    svg {
      path {
        &:first-child {
          fill: none;
        }
      }
    }
  }

  &.DESC {
    svg {
      path {
        &:last-child {
          fill: none;
        }
      }
    }
  }
}

.common-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .export {
    margin-top: 2rem;
    margin-right: 5rem;
  }
}

.widget-container {
  margin-bottom: 3rem;
}

.widget-details {
  display: flex;
  justify-content: center;

  .billing-date {
    font-weight: 500;
    font-size: medium;
    margin-top: 2rem;
  }
}

.activity-heading {
  margin-left: 2rem;
  margin-top: 2rem;
}
.invoices-heading {
  margin-left: 2rem;
}

.activity-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4rem;
  margin-top: 2rem;

  .top-paragraph {
    margin: 0;
  }

  .bottom-paragraph {
    margin: 0;
    margin-top: 1rem;
    align-self: center;
  }
}

.data {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-evenly;
  margin-left: 10rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
}

.dropdown {
  width: 18rem;
}

.button {
  background: $neutral-0;
  color: $neutral-800;
  margin-right: auto;
  padding-right: 1rem;
  width: 100%;
  height: 3.2rem;
  font-size: 2rem;
  cursor: pointer;
  flex-shrink: 0;
  @extend %inline-flex-center;
}

.square-chip {
  border-radius: 6px !important;
  cursor: pointer;
  color: #ff0b0b;
  padding: 4px 8px;
  height: 2rem !important;
}

.download-invoice {
  cursor: pointer;
}

::-webkit-scrollbar {
  height: 0.8rem;
}
