
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: min(1200px, 95%);
  height: 66rem;
  position: relative;
  background: $neutral-g-background;
  border: 1px solid $neutral-g-300;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
