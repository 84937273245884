
          @use "@assets/scss/_config.scss" as *;
        
.modal {
  width: 42rem;
}

.modal-header {
  padding: 0 2rem 0 3rem;
}

.container {
  padding: 2rem 3rem;
  font-size: 1.4rem;
  line-height: 1.7rem;
}

.modal-footer {
  padding: 0 2rem;
}
