
          @use "@assets/scss/_config.scss" as *;
        
.container {
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  min-height: 39.5rem;

  .group {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
}

.input {
  width: 24rem;
}
