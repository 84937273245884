
          @use "@assets/scss/_config.scss" as *;
        
.container {
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  gap: 1rem;

  .button-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2.6rem;
  }

  & > button {
    margin-top: 2.6rem;
  }

  @media screen and (max-width: $screen-l) {
    width: 100%;
    margin: 0;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media screen and (max-width: $screen-s) {
    flex-direction: column;
    width: 100%;
    & > button {
      margin-top: 0;
      width: 15.8rem;
    }
    .button-container {
      margin-top: 0;
      flex-direction: row;
      gap: 1rem;
    }
  }
}
