
          @use "@assets/scss/_config.scss" as *;
        
.container {
  // position: relative;
  width: 100%;
  background: $neutral-g-0;

  // z-index: $view-header-z-index;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  &.active-filter {
    padding: 1.2rem 0 1.7rem;
  }
  .action-block {
    margin-top: 0;
  }
}

@media all and (max-width: 640px) {
  .container {
    padding: 0 1.8rem;
  }
}
