
          @use "@assets/scss/_config.scss" as *;
        
.card {
  min-height: 18rem;
  background-color: $neutral-0;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 3rem 2rem;

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 2;
    gap: 1rem;

    .title {
      @extend %header2;
    }

    .description {
      @extend %body;
      max-width: 34rem;
      flex-grow: 2;
    }
  }
}

.comingSoon {
  position: relative;
}
.comingSoon::before {
  content: '';
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #68636394;
  filter: blur();
}
.comingSoon::after {
  content: 'Coming Soon';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 600;
  color: #fcfafa;
  z-index: 2;
}
