
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  display: grid;
  background: #fff;
  border: 1px solid #eaeaef;
  border-radius: 8px;
}

.tableWrapper {
  padding: 2rem;
}

.header {
  width: 100%;
}

.header h3 {
  @extend %table-label;
  font-weight: bold;
  text-align: left;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  // color: #3c5063;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 450px);
}

.row {
  width: 100%;
  height: 3.3rem;
  display: flex;
  align-items: center;
  padding: 3rem 0;
  position: relative;
}

.row::before {
  position: absolute;
  content: '';
  background: $neutral-200;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}

.text {
  display: grid;
  grid-template-columns:
    minmax(10rem, 13rem) minmax(18rem, 16rem) minmax(4rem, 8rem) minmax(8rem, 13rem)
    minmax(10rem, 12rem) minmax(6rem, 8rem) minmax(4rem, 6rem) minmax(4rem, 7rem) minmax(4rem, 6rem);
  align-items: center;
}

.text h3,
.text p {
  text-align: left;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
}
p {
  font-size: small;
}

.text input[type='checkbox'] {
  margin-left: auto;
  margin-right: 1rem;
}

.state,
.vin {
  overflow-wrap: break-word;
}

#name {
  grid-column: 1 / 2;
}

#vin {
  grid-column: 2 / 3;
}

#state {
  grid-column: 3 / 4;
}

#licensePlate {
  grid-column: 4 / 5;
}

#make {
  grid-column: 5 / 6;
}

#model {
  grid-column: 6 / 7;
}

#year {
  grid-column: 7 / 8;
}

#subscribe {
  grid-column: 8 / 9;
}

#status {
  grid-column: 9 / 10;
}

.loader {
  margin: 17.4rem 48%;
  width: 3rem;
  height: 3rem;
  border-width: 0.5rem;
  place-self: center;
  display: inline-block;
}

.noData {
  padding: 17.4rem 1rem;
  display: flex;
  justify-self: center;
}

.icon {
  svg {
    path {
      stroke-width: 0.5px;
    }
  }

  &.ASC {
    svg {
      path {
        &:first-child {
          fill: none;
        }
      }
    }
  }

  &.DESC {
    svg {
      path {
        &:last-child {
          fill: none;
        }
      }
    }
  }
}
