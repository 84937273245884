
          @use "@assets/scss/_config.scss" as *;
        
.container {
  margin-top: 2.5rem;
  font-size: 1.3rem;

  .greyed-out {
    color: #B3B3B3 !important;
  }
  .table-container {
    max-height: 18vh;
    overflow: auto;
    height: 18vh;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 13vh;
  }

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 13vh;
  }

  h2 {
    color: $neutral-600;
    margin-bottom: 2rem;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
  // thead {
  //   position: sticky;
  //   top: 0;
  // }

  th {
    padding: 0rem 0rem 1rem 3rem;
    border-bottom: 1px solid $neutral-300;
    text-align: left;
    background: #fff;
  }

  td {
    padding: 1rem 0rem 1rem 3rem;
    border-bottom: 1px solid $neutral-300;
    text-align: left;
    word-break: break-word;
  }
}
