
          @use "@assets/scss/_config.scss" as *;
        
.wrapper {
  position: sticky;
  top: 0;
  z-index: 1;

  .container {
    height: fit-content;
    min-height: 8.9rem;
    display: flex;
    width: 100%;
    background: $primary-g-700;
    position: relative;
    padding-left: 5.2rem;
    color: $neutral-g-100;

    .filter-icon {
      position: absolute;
      left: 2.55rem;
      bottom: 1.8rem;
      font-size: 1.8rem;
      line-height: 0.5;
    }

    .columns {
      display: flex;
      width: 100%;
      gap: 1rem;
      padding: 2.1rem 0 1.1rem;
    }

    .columns-control {
      position: sticky;
      right: 0;
      top: 0;
      width: 10.8rem;
      background: linear-gradient(90deg, transparent 0%, $primary-g-700 10%);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
      flex-shrink: 0;

      @media screen and (max-width: $screen-l) {
        display: none;
        // position: fixed;
        // top: 26rem;
        // right: 8rem;
        // background: transparent;
        // width: 14rem;
      }

      // @media screen and (max-width: $screen-s) {
      //   top: 35rem;
      // }

      button {
        background: $neutral-0;
        border: 1px solid $neutral-200;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.8;
        color: $secondary-g-700;
        height: 5.7rem;
        width: 8.8rem;

        @media screen and (max-width: $screen-l) {
          border: none;
          width: 14rem;
          height: 3rem;
        }
      }
    }
  }
}
