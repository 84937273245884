
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  padding: 3rem 2rem;
  display: flex;
  align-items: center;

  .text {
    color: $neutral-g-900;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 600;
  }
}
