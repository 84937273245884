
          @use "@assets/scss/_config.scss" as *;
        
.container {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;

  &[data-switch-is-on='true'] {
    .toggle {
      transition: all 0.3s ease-in;
      justify-content: flex-end;
      background-color: $success-700;
    }

    .label {
      color: $success-700;
    }
  }

  .toggle {
    transition: all 0.3s ease-in;
    justify-content: flex-start;
    background-color: $danger-700;
    width: 4rem;
    height: 2.4rem;
    padding: 0.4rem;
    border-radius: 20px;
    font-size: 1.2rem;
    position: relative;
    display: flex;

    .handle {
      background-color: $neutral-0;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
    }
  }

  .label {
    color: $danger-700;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
