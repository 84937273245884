
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 1.6rem;
}

div.dropdown {
  & > ul {
    max-height: 15rem !important;
  }
}

.filterCheckbox {
  justify-content: left;
  padding: 0 !important;
  margin-left: 1rem
}