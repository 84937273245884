
          @use "@assets/scss/_config.scss" as *;
        
.modal {
  border-radius: 8px;

  // width: 68.3rem;
  width: fit-content;
}

.container {
  height: 25.6rem;
  padding: 3rem 4.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .drag-box span,
  .scan-box span {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: center;
  }

  & > span {
    height: 100%;
  }

  .drag-box {
    border: 2px dashed $primary-600;
    border-radius: 15px;
    height: 100%;
    width: 32.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 1.5rem;
    position: relative;
    cursor: pointer;

    &.dragging {
      cursor: grabbing;
    }

    &.error {
      border-color: $danger-600;

      & > figure {
        color: $danger-600;
      }
    }

    figure {
      width: 6rem;
      height: 6rem;
      margin-inline: auto;
      font-size: 6rem;
      margin-bottom: 0.5rem;
      color: $primary-600;
    }

    button {
      width: fit-content;
      margin-inline: auto;
    }
  }

  .scan-box {
    width: 20rem;
    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background: $neutral-500;
      height: 15rem;
      width: 1px;
      left: -4rem;
    }

    figure {
      width: 10.4rem;
      height: 10.4rem;
      margin-inline: auto;
      font-size: 10.4rem;
    }

    span {
      margin-top: 2rem;
    }
  }
}
