
          @use "@assets/scss/_config.scss" as *;
        
@use '../../assets/columns' as *;

.container {
  position: sticky;
  top: 0;
  z-index: $view-header-z-index;
  border-bottom: 1px solid $neutral-g-300;
  border-top: 1px solid $neutral-g-300;
  background: $neutral-g-100;
  .heading {
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.3rem;
    text-transform: uppercase;
    background: #25477b;
    color: white;
    padding-block: 1.5rem;

    .unit {
      color: $secondary-g-700;
    }

  .heading-group {
    display: flex;
    }
  }
}
