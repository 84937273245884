
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: min(88rem, 90%);
  height: 48rem;
  background: $neutral-0;
  border: 1px solid $neutral-300;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    picture {
      width: 6rem;
      height: 6rem;
    }
  }

  .title {
    margin: 3rem 0 4rem;
    color: $secondary-g-700;
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.6rem;
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    font-size: 1.6rem;
    line-height: 1.9rem;
    width: 90%;

    h3 {
      font-weight: 600;
    }
  }
}
