
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  // padding: 1rem 1.7rem 0 2.1rem;
  margin-top: 3rem;
  margin-left: 4rem;
  .header-title {
    display: flex;
    align-items: center;
    .icon {
      width: 2.4rem;
      height: 2.4rem;
      color: #8e8ea9;
    }
    .cust-icon {
      width: 2.4rem;
      height: 2.1rem;
      color: #8e8ea9;
    }
    .title {
      margin-left: 2rem;
      color: #8e8ea9;
      font-weight: 600;
    }
  }
  .actual {
    color: #2a28f3;
    font-weight: 600;
  }
  .alternative {
    color: #9e33f2;
    font-weight: 600;
  }
  .npvt-positive-text {
    color: #02b502;
  }
  .npvt-negative-text {
    color: red;
  }
}
