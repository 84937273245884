
          @use "@assets/scss/_config.scss" as *;
        
.container {
  padding: 1rem;
  .header {
    font-weight: 600;
  }
  .soft-title {
    color: #8e8ea9;
    font-weight: 600;
  }
  .signal-icon {
    width: 3rem;
    height: 3rem;
  }
  .time-duration {
    font-style: italic;
    font-size: 1.2rem;
  }
}
