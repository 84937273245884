
          @use "@assets/scss/_config.scss" as *;
        
@use 'sass:color';

.container {
  width: 100%;
  height: auto;
  background: $neutral-0;
  border: 1px solid $neutral-200;
  box-sizing: border-box;
  border-radius: 8px;
  z-index: $standard-dropdown-z-index;
  padding: 0.4rem;

  &.is-geotab {
    border-radius: 0;

    * {
      border-radius: 0 !important;
    }
  }

  &[data-popper-reference-hidden='true'] {
    visibility: hidden;
    pointer-events: none;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: pointer;
  }

  .search-bar {
    width: 100%;
    margin-bottom: 0.4rem;
    border-radius: 4px;
  }

  .list {
    max-height: 26rem;
    overflow-y: auto;
    width: 100%;
    margin: 0;
  }

  .loader-box {
    width: 100%;
    margin: 0.8rem auto;
  }
}

@keyframes s3 {
  to {
    transform: rotate(180deg);
  }
}

@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}

.list::-webkit-scrollbar {
  width: 1rem;
}

.list::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: none;
}

.list::-webkit-scrollbar-thumb {
  background: color.adjust($neutral-800, $alpha: -0.7);
  border-radius: 2px;
}

.list-item {
}

.list-item-btn {
  border: 1px solid #bfbffb;
  &:hover {
    color: #2a28f3;
    background: #eeeefe !important;
  }
}
