
          @use "@assets/scss/_config.scss" as *;
        
.container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 1rem 1.7rem 0 2.1rem;
}
