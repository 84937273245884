
          @use "@assets/scss/_config.scss" as *;
        
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.875rem 3.75rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  font-size: 1.25rem;

  h1 {
    color: #2c3e50;
    margin-bottom: 3.125rem;
    padding-bottom: 1.875rem;
    border-bottom: 2px solid #2a28f3;
    font-size: 3.125rem;
  }

  h2 {
    color: #2c3e50;
    margin: 3.125rem 0 1.5625rem;
    font-size: 2.5rem;
  }

  .intro {
    font-size: 1.5625rem;
    line-height: 1.9;
    margin-bottom: 2.5rem;
    color: #34495e;
  }

  .licenseNote {
    color: #666;
    font-style: italic;
    margin-bottom: 2.5rem;
    line-height: 1.9;
  }

  .projectList {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 1.5625rem;
      line-height: 1.9;
      font-size: 1.25rem;
    }

    .projectName {
      color: #2a28f3;
      text-decoration: none;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }

      &::after {
        content: '↗';
        display: inline-block;
        margin-left: 5px;
        font-size: 1em;
      }
    }

    .license {
      display: inline-block;
      padding: 2.5px 10px;
      border-radius: 5px;
      background-color: #f1f1f1;
      font-size: 1.125rem;
      font-weight: 600;
      color: #666;
      margin-left: 10px;
    }
  }

  .license-container {
    background: #f8f9fa;
    padding: 2.5rem;
    border-radius: 10px;
    margin-bottom: 2.5rem;
    box-shadow: 0 2.5px 5px rgba(0, 0, 0, 0.0625);

    h3 {
      color: #2c3e50;
      font-size: 1.875rem;
      margin-bottom: 1.875rem;
      padding-bottom: 0.9375rem;
      border-bottom: 1.25px solid #dee2e6;
    }

    p {
      font-size: 1.40625rem;
      line-height: 1.9;
      margin-bottom: 1.25rem;
      color: #2c3e50;
    }

    .license-type {
      font-weight: 600;
      color: #2a28f3;
      font-size: 1.40625rem;
    }

    .copyright {
      font-style: italic;
      color: #7f8c8d;
      font-size: 1.40625rem;
    }

    a {
      color: #3498db;
      text-decoration: none;
      font-size: 1.40625rem;
      
      &:hover {
        text-decoration: underline;
        color: #2980b9;
      }
    }
  }

  .licenseTexts {
    margin-top: 5rem;
    padding-top: 3.125rem;
    border-top: 1.25px solid #eee;

    h3 {
      color: #2c3e50;
      margin: 3.125rem 0 1.5625rem;
      font-size: 2.34375rem;
    }

    p {
      color: #666;
      line-height: 1.9;
      margin-bottom: 1.5625rem;
      font-size: 1.5625rem;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 2.5rem;
    
    h1 {
      font-size: 2.5rem;
    }
    
    h2 {
      font-size: 2rem;
    }
    
    .intro {
      font-size: 1.40625rem;
    }
    
    .license-container {
      padding: 1.875rem;
      
      h3 {
        font-size: 1.5625rem;
      }
      
      p, .license-type, .copyright, a {
        font-size: 1.25rem;
      }
    }
  }
}
