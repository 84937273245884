
          @use "@assets/scss/_config.scss" as *;
        
.container {
  background: $neutral-100;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10rem 0 4rem;
  position: relative;

  .copyright {
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: center;
    color: $neutral-400;
    @extend %small-text;
  }
}
