
          @use "@assets/scss/_config.scss" as *;
        
.company-container {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-left: 4rem;
  margin-top: 2rem;

  .organization {
    margin-top: 0rem;
  }

  .sub-header {
    margin-top: 3rem;
    font-weight: 600;
  }
}

.address-block {
  margin-bottom: 5rem;
}

.address {
  display: flex;
  flex-direction: row;
}

.values {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

.modal {
  // width: 90rem;
  max-width: 82rem;
  width: calc(100% - 2rem);
}

.small-modal {
  max-height: 82rem;
  height: calc(100% - 22rem);
  overflow-y: auto;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
}
