
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  padding: 0 2rem;
  .main {
    align-items: center;
  }
  .header {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    color: #fff;
    padding: 2rem;
    background: $tertiary-g-600;
  }
  .body {
    height: 45rem;
    padding: 1.4rem 0;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.9rem;
    overflow: auto;
    body {
      min-height: 100%;
      padding: 2rem;
    }
  }

  .non_admin_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 45rem;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 600;
  }

  .footer {
    padding: 1.6rem 0;
    border-top: 1px solid $neutral-g-300;
    width: 100%;
    margin-top: auto;
    position: sticky;
    bottom: 0;
    display: flex;
    background: $neutral-g-100;
    justify-content: flex-end;
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-left: auto;
    margin-right: 5rem;
  }
}

.loader {
  margin: 4rem 45%;
  width: 5rem;
  height: 5rem;
  border-width: 0.7rem;
  place-self: center;
  display: inline-block;
}
