
          @use "@assets/scss/_config.scss" as *;
        
.body {
  padding: 4rem 4.8rem;
  width: 62rem;

  picture {
    padding-top: 3.7rem;
  }
}

.container {
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    height: 5rem;
    @extend %subtitle;
  }

  button {
    width: 40rem;
    margin-block: 3rem 2rem;
    @extend %small-button-text;
  }

  small {
    font-size: 1.4rem;
    line-height: 1.7rem;

    a {
      color: $primary-600;
    }
  }
}
