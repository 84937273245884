
          @use "@assets/scss/_config.scss" as *;
        
.nav-item {
  font-size: 2.2rem;
  height: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  color: $neutral-g-100;
  justify-content: flex-start;
  padding: 0 1rem;
  transition: color 0.2s linear;
  position: relative;
  cursor: pointer;
  gap: 1rem;

  &::after:not(.mobile-nav-item) {
    transition: background-color 0.2s linear;
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: transparent;
  }

  &.active:not(.mobile-nav-item) {
    background: $tertiary-g-500;

    &::after {
      position: absolute;
      content: '';
      background: $neutral-g-100;
      height: 3px;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &:hover:not(.active):not(.mobile-nav-item) {
    transition: color 0.2s linear;


    &::after {
      transition: background-color 0.2s linear;
      background: $neutral-g-100;
    }
  }

  .text {
    position: relative;
    display: inline-flex;
    gap: 0rem;
    justify-content: space-between;
    align-items: center;
    width: auto;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.7rem;
    text-transform: capitalize;

    .text-notifications {
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 700;
      color: $neutral-g-100;
      background: $danger-600;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      margin-left: auto;
      @extend %inline-flex-center;
    }
  }

  .icon {
    position: relative;
    @extend %inline-flex-center;

    &.notifications-icon {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background: $danger-600;
        border: 1px solid $neutral-g-100;
        top: -0.2rem;
        right: 0.6rem;
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }
  }
}

@media all and (max-width: 1300px) {
  .nav-item {
    width: 100%;
    border-radius: 0;
    padding: 0 1rem;

    .icon {
      font-size: 1.8rem;
    }
  }
}

@media all and (max-width: 640px) {
  .nav-item {
    color: $primary-g-700;

    &.mobile-nav-item {
      padding: 0;

      &.active {
        color: $secondary-g-700;
      }

      .text {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }

      .icon {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }
}
