@use './variables/colors' as *;

// typography
@forward './placeholders/typography';

// flex
@forward './placeholders/flex';

// buttons
@forward './placeholders/buttons';

%divider {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    background: $neutral-200;
    height: 1px;
    bottom: 0;
    left: 1.2rem;
    right: 1.2rem;
  }
}
