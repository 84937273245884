
          @use "@assets/scss/_config.scss" as *;
        
.container {
  .icon {
    margin-right: 1rem;
    width: 1.7rem;
    height: 1.7rem;
    vertical-align: middle;
  }

  .content {
    max-height: 40rem;
    overflow: auto;
    scrollbar-width: none;

    .title {
      font-weight: 600;
    }

    .summary {
      display: flex;
      align-items: center;
      font-size: small;

      .savings {
        width: 12rem;
        padding-right: 2rem;
        font-weight: 600;
      }

      .mom-percent {
        width: 12rem;
        padding-right: 1rem;
      }

      .mom-number {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 8rem;
      }

      .mom-value {
        font-weight: 600;
      }

      .mom-per {
        margin-left: 0.5rem;
        color: black !important;
        font-weight: 600;
      }
    }

    .chip-wrapper {
      text-align: center;
      width: 7rem;

      .status-chip {
        border-radius: 0.25rem !important;
        border: 1px solid;
        font-size: smaller;
      }
    }
  }

  .sub-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .potential-savings {
    text-align: end;
    margin-bottom: 1rem;
    font-size: 15px;
    font-weight: 700;

    .value {
      padding-left: 5rem;
      font-weight: 700;
      font-size: 15px;
      display: inline-block;
      min-width: 38rem;
      text-align: start;
    }
  }

  .potential-savings-open {
    margin-right: 26.3rem;
  }

  .potential-savings-closed {
    margin-right: 26rem;
  }

  .updated-by {
    margin-bottom: 0.8rem;
  }
}
