
          @use "@assets/scss/_config.scss" as *;
        
.container {
    background: $tertiary-g-600;
    z-index: $navigation-bar-z-index;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 8.6rem;

    .logo-container {
        display: flex;
        height: 100%;

        .logo {
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 5rem;
            border-right: 1px solid $neutral-g-100;
            height: 100%;
        }

        .tablet-logo {
            display: none;
        }
    }

    .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        flex-grow: 1;
        width: 100%;
        height: 100%;
        color: $geotab-header-text;
        z-index: 3;
    }
}

.left,
.right {
    display: flex;
    align-items: center;
    height: 100%;

    ul {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 3.8rem;

        li {
            display: inline-flex;
            align-items: center;
            height: 100%;
            width: fit-content;

            >* {
                color: $neutral-g-100;
            }
        }
    }
}

.left {
    padding: 0 2.8rem 0 3rem;
    border-right: 1px solid $neutral-200;
    position: relative;
}

.tablet-menu-icon {
    display: none;
}

.mobile-menu {
    display: none;
}

@media all and (max-width: 1450px) {

    .left,
    .right {
        ul {
            gap: 2rem;
        }
    }
}

@media all and (max-width: 1350px) {

    .left,
    .right {
        ul {
            gap: 0rem;
        }
    }

    .left {
        padding: 0 1rem 0 1rem;
    }
}

@media all and (max-width: 950px) {
    .container {
        display: flex;
        align-items: center;
        padding: 0 4rem;

        .logo-container {
            align-items: center;

            .logo {
                display: none;
            }

            .tablet-logo {
                display: inline-block;
                flex-shrink: 0;
                align-items: center;
                justify-content: center;
            }
        }

        .menu {
            display: none;
        }

        .tablet-menu-icon {
            display: flex;
            color: $neutral-g-100;
            font-size: 3.1rem;
            line-height: 2.4rem;
            align-items: center;
            justify-content: center;
            height: 4rem;
        }
    }
}

@media all and (max-width: 640px) {
    .container {
        height: 6rem;
        background: $neutral-g-200;
        padding: 0 2rem;

        .logo-container {
            display: none;
        }

        .tablet-menu-icon {
            display: none;
        }

        .mobile-menu {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 0.65rem;
            height: 100%;

            &>button {
                height: 100%;

                .mobile-visible-link,
                .mobile-menu-icon-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    gap: 0.4rem;
                    transition: opacity 0.3s linear;

                    &:hover {
                        opacity: 0.7;
                        transition: opacity 0.3s linear;
                    }
                }

                &.link {}

                &.mobile-menu-icon {
                    &.active {
                        .mobile-menu-icon-wrapper {
                            color: $secondary-g-700;
                        }
                    }

                    .mobile-menu-icon-wrapper {
                        color: $primary-g-700;

                        .text {
                            font-weight: 600;
                            font-size: 1.2rem;
                            line-height: 1.5rem;
                        }

                        .icon {
                            font-size: 2rem;
                            line-height: 2rem;
                        }
                    }
                }
            }
        }
    }
}

.root-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
}

.dashboard-tab {
    display: flex;
    padding-left: 10rem;
    background-color: #f6f6f9;
    gap: 2rem;
    width: 100%;
    position: fixed;
    left: 0px;
}

.nav-non-collapsed {
    transition: all 0.3s;
}

.nav-collapsed {
    margin-left: 10rem;
    transition: all 0.3s;
}

.dashboard-tab a {
    padding: 1.5rem;
    color: #666687;
    font-weight: 700;
}

.active {
    border-bottom: 3px solid #2a28f3;
    color: #2a28f3 !important;
}