// disabled
$disabled: #666687;

// neutral colors
$neutral-0: #fff;
$neutral-100: #f6f6f9;
$neutral-150: #eaeaef;
$neutral-200: #dcdce4;
$neutral-300: #c0c0cf;
$neutral-400: #a5a5ba;
$neutral-500: #8e8ea9;
$neutral-600: #666687;
$neutral-700: #4a4a6a;
$neutral-800: #32324d;
$neutral-900: #212134;

// neutral geotab colors
$neutral-g-background: #f6f6f9;
$neutral-g-0: #fefefe;
$neutral-g-100: #eff2f7;
$neutral-g-200: #d8dee9;
$neutral-g-300: #c7cbd2;
$neutral-g-500: #66788c;
$neutral-g-900: #202328;

// primary colors
$primary-100: #eaeafe;
$primary-200: #bfbffb;
$primary-500: #7f7ef8;
$primary-600: #2a28f3;
$primary-700: #2827cd;

// primary geotab colors
$primary-g-700: #3c5063;

// secondary colors
$secondary-100: #e8f3fe;
$secondary-200: #bbdcfb;
$secondary-500: #76b8f7;
$secondary-600: #1b89f2;
$secondary-700: #1c74cc;

// secondary geotab colors
$secondary-g-700: #0078d3;

// tertiary colors
$tertiary-100: #f5ebfe;
$tertiary-200: #e2c2fb;
$tertiary-500: #c585f7;
$tertiary-600: #9e33f2;
$tertiary-700: #852fcc;

// tertiary geotab colors
$tertiary-g-500: #426499;
$tertiary-g-600: #25477b;
$tertiary-g-700: #002d4f;

// success colors
$success-100: #ebf9ee;
$success-200: #c2eecd;
$success-500: #85dd9b;
$success-600: #34c759;
$success-700: #30a652;

// warning colors
$warning-100: #fff4e6;
$warning-200: #ffdfb3;
$warning-500: #ffbf66;
$warning-600: #ff9500;
$warning-700: #d37e0a;

// danger colors
$danger-100: #ffe8e7;
$danger-200: #ffb9b5;
$danger-500: #ff746c;
$danger-600: #ff170a;
$danger-700: #d31912;

// data colors
$data-color-1: #ff3b30;
$data-color-2: #ff9500;
$data-color-3: #fc0;
$data-color-4: #34c759;
$data-color-5: #5ac8fa;
$data-color-6: #007aff;
$data-color-7: #5856d6;
$data-color-8: #af52de;
$data-min-gradient: #5ac8fa;
$data-max-gradient: #5856d6;

// geotab colors
$geotab-header-background: #25477b;
$geotab-header-text: #eff2f7;
