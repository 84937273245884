
          @use "@assets/scss/_config.scss" as *;
        
.container {
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;

  &.container-value {
    .placeholder {
      color: #000000 !important;
    }
  }
  .soft-placeholder {
    color: #8e8ea9;
    text-transform: capitalize;
    white-space: nowrap;
  }
}

[data-select-is-tags='true'] {
  &.container {
    color: $neutral-500;

    &.tags {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      overflow-x: auto;
      padding-block: 0.2rem;
      padding-right: 0.2rem;

      &::-webkit-scrollbar {
        height: 4px;
      }
    }
  }
}
