
          @use "@assets/scss/_config.scss" as *;
        
.container {
  width: 100%;
  min-height: 53.2rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
