
          @use "@assets/scss/_config.scss" as *;
        
.picture {
  display: block;
  width: 2rem;
  height: 2rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.letter-box {
  width: 2rem;
  height: 2rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1;
  color: #fff;
  border-radius: 4px;

  @extend %inline-flex-center;
}
