
          @use "@assets/scss/_config.scss" as *;
        
.container {
  background: $neutral-0;
  border: 1px solid $neutral-150;
  box-sizing: border-box;
  border-radius: 2px;
  width: 1.4rem;
  height: 2.4rem;
  position: absolute;
  right: 0;
  top: 2.8rem;
  @extend %flex-center;

  .icon {
    transform: rotate(0deg);
    color: $neutral-600;
    font-size: 0.8rem;

    &.open {
      transform: rotate(180deg);
    }
  }
}

.splitter-container {
  background: $neutral-0;
  border: 1px solid #eaeaef;
  box-sizing: border-box;
  border-radius: 2px;
  width: 1.4rem;
  height: 2.4rem;
  position: absolute;
  right: 0;
  left: -0.5px;
  z-index: 99;
  top: 7%;
  @extend %flex-center;

  &:hover {
    background: #eaeaef;
  }

  .icon {
    transform: rotate(0deg);
    color: $neutral-600;
    font-size: 0.8rem;

    &.open {
      transform: rotate(180deg);
    }
  }
}
