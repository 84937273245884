
          @use "@assets/scss/_config.scss" as *;
        
.container {
  position: relative;
  width: 100%;
  background: $neutral-g-0;
  z-index: $view-header-z-index;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.8rem 0;

  &.active-filter {
    padding: 1.2rem 0 1.7rem;
  }

  .title {
    display: flex;
    flex-direction: column;

    .name {
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.6rem;
      text-transform: capitalize;
      margin-bottom: 0.6rem;
    }

    button {
      gap: 1.25rem;
      height: 3.2rem;
    }
  }

  .entries,
  .subtitle,
  .customer {
    color: $neutral-600;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  .customer {
    margin-bottom: 0.6rem;
    text-transform: capitalize;
  }

  .superadmin {
    font-weight: 600;
    color: #2a28f3;
    margin-bottom: 0.6rem;
  }

  .subtitle {
    text-transform: capitalize;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
