
          @use "@assets/scss/_config.scss" as *;
        
.container {
  .numbers {
    text-align: end;
    margin-left: 0.5rem;
    display: block;
  }

  .description {
    margin-left: 5rem;
    line-height: 2rem;
    font-weight: 400;
  }

  .toll-list {
    .right-arrow {
      margin-top: 1rem;
      margin-left: 2.5rem;
    }
  }

  h3 {
    line-height: 3.2rem;
    font-size: small;
    margin-left: 0.6rem;
  }

  .amount {
    font-weight: 600;
    line-height: 3.2rem;
    font-size: small;
  }

  .sub-category-tbl {
    border-collapse: collapse;
  }
}
