
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 50vh;
  width: 100%;
}

.trip-container {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  flex-grow: 1;
  width: 100%;
}
