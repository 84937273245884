
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  // margin-bottom: 1rem;
  // margin-top: 2rem;
  flex-direction: column-reverse;
  gap: 1rem;
}

@media all and (max-width: 640px) {
  .container {
    padding: 0 1.8rem;
    align-items: flex-start;
  }
}
