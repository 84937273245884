
          @use "@assets/scss/_config.scss" as *;
        
.container {
  background: $neutral-0;
  width: 78rem;
  border-radius: 8px;

  &.is-geotab {
    border-radius: 0;

    * {
      border-radius: 0 !important;
    }
  }
}

.small-modal {
  max-height: 82rem;
  height: calc(100% - 22rem);
  overflow: auto;
  transform: unset !important;
}

.is-geotab {
  *::-webkit-scrollbar-thumb,
  *::-webkit-scrollbar-track {
    border-radius: 0 !important;
  }
}
