
          @use "@assets/scss/_config.scss" as *;
        
.container {
  .error {
    text-align: center;
    padding-top: 8rem;

    .retry {
      padding-top: 1rem;

      .icon {
        width: 1.1rem;
      }
    }
  }

  .loader {
    text-align: center;
    padding-top: 8rem;
  }
}

.barChart {
  height: 50rem;
}
