
          @use "@assets/scss/_config.scss" as *;
        
.container {
  padding: 1rem 4rem;
  display: flex;
  justify-content: space-between;

  .notice {
    color: $neutral-g-900;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
  }
}
