
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: $slider-dropdown-z-index;
  position: relative;
  label {
    font-weight: 600;
    @extend %small-text;

    text-transform: capitalize;
    padding-left: 1.2rem;
    z-index: 0;
  }

  &.container-error {
    .error-description {
      padding-left: 1.2rem;
      margin-top: 0.4rem;
      color: $danger-600;
      @extend %small-text;
    }
  }
}

.max-width {
  max-width: 15rem;
}

.clear {
  @extend %inline-flex-center;

  &:hover {
    color: $danger-600;
  }
}

.dropdown {
  width: auto;
  z-index: 1000;
}

.disable-slider {
  background: #f6f6f9 !important;
  cursor: not-allowed !important;
}

.slider-container {
  padding: 1rem 1.5rem !important;
  background: $neutral-0;
  border: 1px solid $neutral-200;
  border-radius: 8px;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  z-index: 0;
  position: relative;
  overflow: hidden;

  --icon-box-width: 4rem;

  transition: all 0.3s ease;

  &[data-select-error='true'] {
    border: 1px solid $danger-600;

    .icon-container {
      border-color: $danger-600;
      color: $danger-600;
    }

    &:hover {
      border: 1px solid $danger-600;

      .icon-container {
        border-color: $danger-600;
      }
    }
  }

  .arrow {
    &.arrow-open {
      transform: rotate(-180deg);
    }
  }

  .icon-container {
    width: var(--icon-box-width);
    height: 100%;
    background: $neutral-100;
    border-right: 1px solid $neutral-200;
    color: $primary-600;
    font-size: 1.6rem;
    position: absolute;
    left: 0;
    @extend %inline-flex-center;

    & ~ .container-selection {
      padding-left: var(--icon-box-width);
    }
  }

  &:hover {
    transition: all 0.3s ease;
    border: 1px solid $primary-200;
    background: $neutral-0;

    .icon-container {
      border-color: $primary-200;
    }
  }

  &.container-active {
    border: 1px solid $primary-600;

    .icon-container {
      border-color: $primary-600;
    }
  }
}

[data-select-is-tags='true'] {
  .container {
    padding: 0.4rem;
    padding-right: 0.6rem;
  }
}

[data-select-input-disabled='true'] {
  .container {
    border: 1px solid $neutral-200;
    background: $neutral-100;
    cursor: not-allowed;

    &:hover {
      border: 1px solid $neutral-200;
      background: $neutral-100;

      .icon-container {
        border-color: $neutral-200;
      }
    }
  }
}
