
          @use "@assets/scss/_config.scss" as *;
        
.container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  height: fit-content;

  color: $primary-600;
  font-size: 3rem;

  .icon {
    display: inline-flex;
    height: fit-content;
  }
}

.is-geotab {
  top: 10rem;
  right: 4rem;
  color: $secondary-g-700;
}
