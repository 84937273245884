
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-direction: column;

  .name {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.6rem;
    text-transform: capitalize;
    margin-bottom: 0.6rem;
  }

  button {
    gap: 1.25rem;
    height: 3.2rem;
  }

  .entries,
  .subtitle,
  .customer {
    color: $neutral-600;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  .customer {
    margin-bottom: 0.6rem;
    text-transform: capitalize;
  }

  .subtitle {
    text-transform: capitalize;
  }
}
