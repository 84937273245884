
          @use "@assets/scss/_config.scss" as *;
        
.container {
  .error {
    text-align: center;
    padding-top: 8rem;

    .retry {
      padding-top: 1rem;

      .icon {
        width: 1.1rem;
      }
    }
  }

  .loader {
    text-align: center;
    padding-top: 8rem;
  }
}
.gridContainer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
  margin: 0.5rem;
}

.textContainer {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.totalText {
  text-align: start;
  margin-bottom: 1rem;
  font-size: 15px;
  font-weight: 700;
}

.totalTextvalue {
  padding-left: 2rem;
  margin-bottom: 1rem;
  font-weight: 550;
  font-size: 15px;
  text-align: end;
}

.totalTolls {
  color: #808080;
  margin-bottom: 1rem;
  padding-left: 1rem;
  font-weight: 700;
  font-size: 15px;
  text-align: end;
}

.barChart {
  height: 50rem;
  padding-top: 2rem;
}
