
          @use "@assets/scss/_config.scss" as *;
        
.container {
  background: $neutral-0;
  width: 25.4rem;
  height: 4rem;
  border: 1px solid $neutral-200;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  z-index: 1;
  position: relative;

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
    cursor: pointer;
    background: $neutral-0;
    border-radius: 7px;
    transition: background 0.2s linear;
    @extend %small-button-text !optional;

    &.active {
      border-radius: 7px;
      transition: background 0.2s linear;
      color: $neutral-0;

      &.actual {
        background: $primary-600;
        color: $neutral-0;
      }

      &.alternative {
        background: $tertiary-700;
        color: $neutral-0;
      }

      &.noData {
        background: lightgray;
        color: grey;
      }
    }
  }
}
