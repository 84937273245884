
          @use "@assets/scss/_config.scss" as *;
        
.sortLabel {
  display: flex;
  align-items: center;
}

.iconb {
  margin-left: 8px;
  font-size: 1.2rem;
  cursor: pointer;

  svg {
    path {
      color: '#000000';
      stroke: '#000000';
      stroke-width: 0.5px;
    }
  }
}

.icon {
  margin-left: 8px;
  font-size: 1.2rem;
  cursor: pointer;

  svg {
    path {
      color: $primary-600;
      stroke: $primary-600;
      stroke-width: 0.5px;
    }
  }

  &.asc {
    svg {
      path {
        &:first-child {
          fill: none; // Modify visual state for ascending order
        }
      }
    }
  }

  &.desc {
    svg {
      path {
        &:last-child {
          fill: none; // Modify visual state for descending order
        }
      }
    }
  }
}

.heading {
  text-align: left;
  white-space: wrap;
  overflow: hidden;
  text-overflow: break;
}

.headinga {
  text-align: left;
  white-space: wrap;
  overflow: hidden;
  text-overflow: break;
  color: $primary-600;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

// .tabs {
//     font-size: 14px;
//     min-width: auto;
//     padding: 10px 10px;
// }

.buttonWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}

.selectBox {
  display: flex;
  align-items: center;
  gap: 16px;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.tableContainer {
  flex: 1;
  max-height: 80vh;
  border: 1px solid $neutral-200;
}

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: none;
}

.stickySearchRow {
  position: sticky;
  top: 46px;
  z-index: 1;
  border-top: none;
}

// .tableCell {
//     font-weight: bold;
//     font-size: 12px;
//     width: 200px;
//     padding-bottom: 0;
//     background-color: $primary-g-700;
//     border-bottom: none;
//     color: $neutral-g-100;
// }

.searchCell {
  background-color: $primary-g-700;
  color: $neutral-g-100;
}

.inputWrapper {
  position: relative;
  width: 100%;
}

.inputField {
  width: 100%;
  padding: 10px 30px 5px 5px;
  font-size: 12px;
  box-sizing: border-box;
  background-color: $primary-g-700;
  border: 1px solid $neutral-200;
  color: $neutral-g-100;

  &:focus {
    outline: none;
    // border-color: $primary-g-500;
  }
}

.clearButton {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: $primary-g-700;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: red;
    color: white;
  }
}

.tableBodyCell {
  text-align: left;
  font-size: 12px;
  width: 200px;
}

// .tableContainer::-webkit-scrollbar {
//     width: 10px;
//     height: 10px;
// }

// .tableContainer::-webkit-scrollbar-track {
//     background: #f0f0f0;
// }

// .tableContainer::-webkit-scrollbar-thumb {
//     background: $primary-g-700;
// }

// .tableContainer::-webkit-scrollbar-thumb:hover {
//     background: $primary-g-700;
// }

// .select {
//     width: 140px;
//     height: 40px;
//     background-color: #f9f9f9;
//     font-size: 12px;
//     color: #333;

//     .MuiOutlinedInput-notchedOutline {
//         border-color: #888;
//     }

//     &:hover {
//         .MuiOutlinedInput-notchedOutline {
//             border-color: #888;
//         }
//     }

//     &.Mui-focused {
//         .MuiOutlinedInput-notchedOutline {
//             border-color: #888;
//         }
//     }

//     .MuiSelect-icon {
//         color: #0056b3;
//     }
// }
