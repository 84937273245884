
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 24rem;
  position: relative;

  .date-picker-error {
    color: red;
    height: 0;
  }

  label {
    font-weight: 600;
    @extend %small-text;

    text-transform: capitalize;
    padding-left: 1.2rem;
    z-index: 0;
  }

  .container-display {
    margin-top: 0.6rem;
    background: $neutral-0;
    border: 1px solid $neutral-200;
    border-radius: 8px;
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;


    position: relative;

    --date-picker-width: 100%;
    --icon-box-width: 4rem;

    transition: all 0.3s ease;

    &.focused {
      border: 1px solid $secondary-g-700;

      .icon-container {
        border-color: $secondary-g-700;
      }
    }

    &:hover:not(.focused) {
      transition: all 0.3s ease;
      border: 1px solid $secondary-500;
      background: $neutral-0;

      .icon-container {
        border-color: $secondary-500;
      }
    }

    .icon-container {
      width: var(--icon-box-width);
      height: 100%;
      background: $neutral-100;
      border-right: 1px solid $neutral-200;
      color: $secondary-g-700;
      font-size: 1.6rem;
      flex-shrink: 0;
      border-radius: 8px 0 0 8px;
      @extend %inline-flex-center;

      position: relative;
      z-index: 1;

      & ~ div {
        input {
        }
      }
    }

    & > div:first-child {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: space-between;
      width: 100%;
    }

    input {
      border-radius: 8px;
      padding: 0 1.2rem 0 calc(var(--icon-box-width) + 1.2rem);
      height: 100%;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      border: 0;
      outline: 0;
      font-family: $primary-font;

      &::placeholder {
        color: $neutral-500;
      }
    }

    .arrow {
      display: flex;
      align-items: center;
      gap: 0.1rem;
      color: $neutral-800;
      font-size: 1.7rem;
      position: absolute;
      right: 1rem;

      .icon {
        &.icon-open {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &.container-error {
    .container-display {
      border: 1px solid $danger-600;

      .icon-container {
        border-color: $danger-600;
        color: $danger-600;
      }

      &:hover {
        border: 1px solid $danger-600;

        .icon-container {
          border-color: $danger-600;
        }
      }
    }

    .error-description {
      padding-left: 1.2rem;
      margin-top: 0.4rem;
      color: $danger-600;
      @extend %small-text;
    }
  }

  &.disabled {
    .container-display {
      background: $neutral-100;
      cursor: not-allowed;

      input {
        background: $neutral-100;
        cursor: not-allowed;
      }

      &:hover {
        border: 1px solid $neutral-200;
        background: $neutral-100;

        .icon-container {
          border-color: $neutral-200;
        }
      }
    }
  }
}
