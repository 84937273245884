
          @use "@assets/scss/_config.scss" as *;
        
.container {
  margin-top: 1rem;
  // margin-top: 2rem;
  width: 100%;
  display: grid;
  border: 1px solid #eaeaef;
  border-radius: 8px;

  .header {
    width: 100%;

    h3 {
      @extend %table-label;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .row {
    width: 100%;
    min-height: 5.5rem;
    display: flex;
    align-items: center;
    padding: 0 1.6rem;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      background: $neutral-200;
      height: 1px;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .text {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(9, 1fr);
    .not-found {
      color: $danger-600;
    }

    .search-highlight {
      display: inline-block;
      background: $primary-100;
      border-radius: 2px;
    }

    .date {
      grid-column: 1 / 3;
      max-width: 12rem;
      overflow-wrap: break-word;
    }

    .state {
      grid-column: 3 / 4;
    }

    .agency {
      grid-column: 4 / 5;
      max-width: 6rem;
      overflow-wrap: break-word;
    }

    .facility {
      grid-column: 5 / 7;
      max-width: 14rem;
      overflow-wrap: break-word;
    }

    .plaza {
      grid-column: 7 / 9;
      max-width: 14rem;
      overflow-wrap: break-word;
    }

    .toll {
      grid-column: 9 / 10;
    }
  }

  abbr[title] {
    text-decoration: none !important;
  }
}
