@use '../../../../assets/scss/config' as *;

.item {
  min-width: 22rem;
  min-height: 5.5rem;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px solid $neutral-g-300;
  }

  &:is(.vehicle-id, .vin, .vehicle-name,.vehicle-make,.vehicle-class,.vehicle-model, .license-plate-number,.tag-transponder,.tag-issuing-agency) {
    padding: 1rem 2rem; 
  }

  &:is(.tag-transponder){
    min-width: 24rem;
  }

  .tag-type {
    margin-top: 0 !important;
  }

  &:not(.vehicle-id, .vin, .vehicle-name, .license-plate-number ,.tag-transponder,.tag-issuing-agency) {
    width: 25rem;
    padding: 0 2rem;
  }
}
