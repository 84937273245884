
          @use "@assets/scss/_config.scss" as *;
        
.container {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  padding: 1rem 2rem 1rem 2.4rem;
  width: 100%;
  height: fit-content;
  @extend %divider;

  .objects {
    @extend %small-button-text;
  }
}
