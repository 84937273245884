@use '../variables/colors' as *;

// primary
%icon-primary {
  background: $primary-600;
  color: $neutral-0;

  &:hover {
    background: $primary-500;
  }

  &:focus {
    background: $primary-700;
  }
}

// secondary
%icon-secondary {
  background: $neutral-150;
  border: 1px solid $primary-200;
  color: $primary-600;

  &:hover {
    background: $neutral-0;
  }

  &:focus {
    background: $neutral-0;
    border: 1px solid $primary-700;
    color: $primary-700;
  }
}

// geotab secondary
%icon-g-secondary {
  background: $secondary-g-700;
  border: 1px solid $secondary-g-700;
  color: $neutral-g-100;
}

// tertiary
%icon-tertiary {
  background: $neutral-0;
  border: 1px solid $neutral-200;
  color: $neutral-800;

  &:hover {
    background: $neutral-100;
  }

  &:focus {
    background: $neutral-150;
  }
}

// success primary
%icon-success-primary {
  background: $success-600;
  color: $neutral-0;

  &:hover {
    background: $success-500;
  }

  &:focus {
    background: $success-700;
  }
}

// success secondary
%icon-success-secondary {
  background: $success-100;
  border: 1px solid $success-200;
  color: $success-600;

  &:hover {
    background: $neutral-0;
  }

  &:focus {
    background: $neutral-0;
    border: 1px solid $success-700;
    color: $success-700;
  }
}

// danger primary
%icon-danger-primary {
  background: $danger-600;
  color: $neutral-0;

  &:hover {
    background: $danger-500;
  }

  &:focus {
    background: $danger-700;
  }
}

// danger secondary
%icon-danger-secondary {
  background: $danger-100;
  border: 1px solid $danger-200;
  color: $danger-600;

  &:hover {
    background: $neutral-0;
  }

  &:focus {
    background: $neutral-0;
    border: 1px solid $danger-700;
    color: $danger-700;
  }
}

// disabled

%icon-button-disabled {
  color: $disabled;
  background: $neutral-150;
  border: 1px solid $neutral-300;
  cursor: not-allowed;

  &:hover {
    color: $disabled;
    background: $neutral-150;
    border: 1px solid $neutral-300;
  }
}
