
          @use "@assets/scss/_config.scss" as *;
        
.edit-container {
  display: flex;
  justify-content: space-between;
  height: 60vh;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.9rem 2rem;
  padding: 1rem 3rem;
  height: 48rem;
  align-content: flex-start;
  overflow: auto;

  .input {
    width: 35rem;
  }
}

.small-screen {
  height: 35rem;
}

.address-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sub-header {
  font-weight: 700;
}

.checkbox-header {
  min-height: 4rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.checkbox-name {
  display: flex;
  gap: 0.1rem;
  align-items: center;
  margin-bottom: 0.2rem;
}
